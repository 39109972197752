@charset "UTF-8";

/* ===================================================================

index style

=================================================================== */

.top-kv {
  @include flex;
  align-items: center;
  justify-content: center;
  height: 640px;
  @include mqXS {
    height: auto;
    height: 495px;
  }
  .kv-inner {
    width: 100%;
    height: 100%;
    position: relative;
    > span {
      width: 100%;
      height: 100%;
    }
    @include mqXS {
      img.ofi {
        object-position: top;
      }
    }
  }
  .box {
    position: absolute;
    bottom: 40px;
    left: calc((100% - 1200px) / 2);
  }
}

.top-story {
  background: url(../images/title_bg.png) no-repeat top center / auto auto;
  padding-top: 60px;
  margin-bottom: 80px;
  @include mqLG {
    background-size: 100% auto;
  }
  @include mqXS {
    background-size: auto 140px;
    padding-top: 30px;
  }
  .common-title01 {
    color: #fff;
    margin-bottom: 80px;
    @include mqXS {
      margin-bottom: 40px;
    }
  }
  p {
    padding: 50px 0;
    line-height: 2.4;
  }
}

.top-category {
  background: $clr04 url(../images/top_category_bg.png) no-repeat bottom left;
  padding: 70px 0;
  margin-bottom: 80px;
  @include mqXS {
    background-size: 30% auto;
    padding: 20px 4%;
  }
  .category-box {
    max-width: 1326px;
    width: 100%;
    margin: 0 auto;
    padding: 60px 60px 30px 60px;
    background: #fff;
    position: relative;
    @include mqXS {
      padding: 20px 4%;
    }
  }
  .top-cat-ico {
    position: absolute;
    top: -100px;
    right: 50px;
    @include mqXS {
      width: 30%;
      right: 0%;
      top: -30px;
    }
  }
  .common-title01 {
    position: relative;
    z-index: 2;
  }
  ul {
    @include flex;
    &.col2,
    &.col3-list {
      justify-content: space-between;
    }
    &.col3-list {
      li {
        width: 30%;
      }
    }
    &.half-width {
      li {
        width: 48%;
      }
    }
    &.left-wide {
      li {
        &:last-of-type {
          width: 30%;
        }
        &:first-of-type {
          width: 66%;
        }
      }
    }
    &.right-wide {
      li {
        &:last-of-type {
          width: 66%;
        }
        &:first-of-type {
          width: 30%;
        }
      }
    }
    &.col1-list {
      flex-direction: column;
    }
    li {
      margin-bottom: 30px;
      @include mqXS {
        margin-bottom: 14px;
      }
      a {
        display: block;
        @include mqSM {
          transition: 0.2s;
          img {
            transition: 0.2s;
          }
          &:hover {
            color: #111;
            img {
              opacity: 0.8;
            }
          }
        }
      }
    }
    h3 {
      font-size: 1.8rem;
      font-weight: bold;
      padding: 10px 0 10px 0;
      @include mqXS {
        font-size: 1.2rem;
        padding: 4px 0 0 0;
        line-height: 1.3;
      }
    }
    p {
      line-height: 1.8;
      text-align: justify;
      letter-spacing: 0.05em;
      @include mqXS {
        display: none;
      }
    }
  }
}

.top-howto {
  margin-bottom: 80px;
}

.howto-box {
  background: #f8f9fb url(../images/top_howto_bg.jpg) no-repeat top right / auto
    100%;
  padding: 40px 40px 20px 40px;
  @include mqXS {
    padding: 15px 4% 15px 4%;
    position: relative;
    background: #f8f9fb url(../images/top_howto_bg.jpg) no-repeat 60% 50% / auto
      100%;
    margin-bottom: 120px;
  }
  h2 {
    display: inline-block;
    color: $clr03;
    border: 1px solid $clr03;
    font-size: 3.2rem;
    line-height: 1;
    padding: 15px 40px;
    border-radius: 100px;
    margin-bottom: 30px;
    @include mqXS {
      font-size: 2rem;
      padding: 10px 30px;
    }
  }
  p {
    margin-bottom: 20px;
    line-height: 2;
    &.howto-txt {
      @include mqXS {
        position: absolute;
        bottom: -90px;
        left: 0;
        margin-bottom: 0;
      }
    }
  }
}

.top-ranking {
  background: $clr01;
  margin-bottom: 80px;
  padding: 80px 0;
  .common-title01 {
    color: #fff;
  }
}

.ranking-list {
  padding-bottom: 20px;
  li {
    margin-bottom: 20px;
  }
  a {
    display: block;
    color: #fff;
    padding: 20px;
    @include mqSM {
      transition: 0.2s;
      &:hover {
        background: #005634;
      }
    }
    @include mqXS {
      padding: 0 6%;
    }
    .thumb {
      margin-bottom: 10px;
      img {
        width: 100%;
      }
    }
    h3 {
      margin-bottom: 15px;
    }
  }
}

.top-news {
  margin-bottom: 80px;
}

.news-list {
  li {
    a {
      @include flex;
      border-bottom: 1px solid #ccc;
      padding: 20px 0;
      @include commonHover;
      .date {
        width: 120px;
      }
      .title {
        width: calc(120% - 100px);
      }
    }
  }
}

.foot-content {
  margin-bottom: 80px;
  ul {
    li {
      border: 1px solid #ccc;
      width: 23%;
      @include mqXS {
        width: 48%;
        margin-bottom: 15px;
      }
      a {
        padding: 20px;
        display: block;
        @include commonHover;
        @include mqXS {
          padding: 10px;
        }
        h3 {
          text-align: center;
          margin-bottom: 20px;
          font-size: 2.2rem;
          @include mqXS {
            font-size: 1.4rem;
            margin-bottom: 10px;
          }
        }
        .ico {
          text-align: center;
          margin-bottom: 20px;
          @include mqXS {
            margin-bottom: 10px;
            height: 45px;
            @include flex;
            align-items: center;
            justify-content: center;
            img {
              max-height: 100%;
            }
          }
        }
      }
    }
  }
}
