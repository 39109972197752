@charset "UTF-8";

/* ===================================================================

subpages style

=================================================================== */

.sub-kv {
  height: 300px;
  margin-top: $hh;
  margin-bottom: 80px;
  @include flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: url(../images/sub/common_sub_kv.jpg) no-repeat center center /
    cover;
  @include mqXS {
    margin-top: 0;
    margin-bottom: 40px;
    height: 140px;
  }
  .common-title01 {
    margin-bottom: 0;
  }
}

.about-cont {
  margin-top: -80px;
  @include mqXS {
    margin-top: -40px;
  }
  .box {
    max-width: 1000px;
  }
  section {
    margin-bottom: 40px;
    p {
      line-height: 2.2;
      letter-spacing: 0.1em;
    }
  }
}

.thumb {
  &.fl {
    margin-right: 20px;
    margin-bottom: 20px;
    @include mqXS {
      max-width: 40%;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  &.fr {
    margin-left: 20px;
    margin-bottom: 20px;
    @include mqXS {
      max-width: 40%;
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
}

.about-cont01 {
  background: url(../images/sub/about_bg01.jpg) no-repeat top center / cover;
  color: #fff;
  padding: 60px 0;
  position: relative;
  @include mqXS {
    padding: 30px 0;
  }
  p {
    line-height: 2.4;
  }
  .txt-box {
    padding-right: 10px;
    width: 70%;
    @include mqXS {
      padding-right: 0;
      width: 100%;
    }
  }
  .thumb {
    @include mqXS {
      text-align: center;
      padding-top: 20px;
      img {
        max-width: 50%;
      }
    }
  }
}

.about-cont02 {
  background: url(../images/sub/about_bg02.jpg) no-repeat top right;
  padding-top: 100px;
  margin-top: -40px;
  @include mqXS {
    background-size: 60% auto;
  }
}

.pink-bg {
  background: $clr09;
  padding: 80px 0;
}

.about-cont03 {
  h3 {
    &:not(:first-of-type) {
      margin-top: 40px;
    }
  }
}

.common-bnr {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  padding: 80px 3% 0 3%;
  @include mqXS {
    width: 100%;
    overflow: hidden;
    padding: 60px 4% 0 4%;
  }
  a {
    display: block;
    position: relative;
    max-width: 912px;
    @include commonHover;
    > img {
      @include mqXS {
        height: 180px;
        max-width: none;
        width: auto;
        transform: translateX(-10%);
      }
    }
    p {
      color: $clr01;
      font-size: 2.8rem;
      font-weight: bold;
      margin-bottom: 20px;
      position: absolute;
      top: 40px;
      width: 100%;
      text-align: center;
      @include mqXS {
        font-size: 1.8rem;
      }
    }
    .btn-purchase {
      display: inline-block;
      background: $clr03;
      color: #fff;
      font-size: 1.6rem;
      padding: 15px 0px;
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      margin: auto;
      width: 260px;
      @include mqXS {
        padding: 10px 0;
        width: 220px;
      }
      &:after {
        content: "";
        width: 30px;
        height: 4px;
        position: absolute;
        right: 10px;
        top: calc(50% - 2px);
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        transform: skew(40deg);
      }
    }
  }
}

.report-cont {
  background: url(../images/sub/report_bg.jpg) repeat top left;
  padding: 40px;
  @include mqXS {
    padding: 20px 4%;
  }
  p {
    line-height: 2.2;
    margin-bottom: 20px;
  }
  section {
    margin-bottom: 40px;
    position: relative;
  }
  .btn-more {
    color: #535353;
    border-color: #535353;
  }
}

.report-title01 {
  margin-bottom: 40px;
  text-align: center;
  @include mqXS {
    margin-bottom: 20px;
  }
}

.report-title02 {
  margin-bottom: 20px;
  border-left: 5px solid $clr01;
  font-weight: bold;
  font-size: 2rem;
  padding-left: 10px;
  line-height: 1.3;
  @include mqXS {
    font-size: 1.8rem;
  }
}

.report-cont01 {
  .thumb {
    float: right;
    margin-top: -80px;
    margin-left: 20px;
    @include mqXS {
      float: none;
      margin: 0px auto 20px auto;
      width: 70%;
    }
  }
}

.report-cont02 {
  .thumb {
    @include mqXS {
      width: 70%;
      margin: 0 auto 20px auto;
      float: none;
    }
  }
}

.report-cont06 {
  .report-title01 {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }
  .thumb {
    @include mqXS {
      width: 90%;
      margin: -40px auto 0px auto;
    }
  }
}

.more-cont {
  display: none;
}

.howto-cont,
.uv-cont,
.doctor-cont {
  section {
    margin-bottom: 80px;
    padding: 0 20px;
    @include mqXS {
      margin-bottom: 40px;
    }
  }
  .intro {
    margin-bottom: 80px;
    @include mqXS {
      margin-bottom: 30px;
    }
    p {
      line-height: 2;
    }
  }
  .lead-txt {
    margin-bottom: 40px;
    line-height: 2;
    @include mqXS {
      margin-bottom: 20px;
    }
  }
}

.howto-title01 {
  text-align: center;
  overflow: hidden;
  color: $clr01;
  font-weight: bold;
  font-size: 2.2rem;
  margin-bottom: 40px;
  @include mqXS {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
  span {
    position: relative;
    &:before {
      content: "";
      background: url(../images/sub/step_title_bg.png) repeat-x center center;
      width: 600px;
      height: 12px;
      position: absolute;
      left: -620px;
      top: calc(50% - 6px);
      @include mqXS {
        background: none;
      }
    }
    &:after {
      content: "";
      background: url(../images/sub/step_title_bg.png) repeat-x center center;
      width: 600px;
      height: 12px;
      position: absolute;
      right: -620px;
      top: calc(50% - 6px);
      @include mqXS {
        background: none;
      }
    }
  }
}

.step-list {
  width: 105.3%;
  transform: translateX(-2.65%);
  counter-reset: stepNum;
  @include mqXS {
    width: 100%;
    transform: translateX(0%);
  }
  &.step-green {
    li {
      &:before {
        background: $clr01;
      }
      &:after {
        background: url(../images/sub/step_arw_g.png) no-repeat top left;
        @include mqXS {
          background-size: 100% auto;
        }
      }
    }
  }
  li {
    padding: 0 2.65%;
    position: relative;
    margin-bottom: 40px;
    counter-increment: stepNum;
    @include mqXS {
      padding: 0 0 45px 0;
      width: 100%;
      @include flex;
    }
    &:before {
      content: "STEP" counter(stepNum);
      position: absolute;
      left: 0;
      right: 0;
      top: -15px;
      margin: auto;
      width: 80px;
      text-align: center;
      display: inline-block;
      background: $clr08;
      color: #fff;
      font-size: 2rem;
      padding: 5px 15px;
      line-height: 1;
      border-radius: 40px;
      letter-spacing: 0.1em;
      @include mqXS {
        font-size: 1.4rem;
        top: 0px;
        right: auto;
        left: calc(40% + 4%);
      }
    }
    &:after {
      content: "";
      width: 36px;
      height: 110px;
      background: url(../images/sub/step_arw_r.png) no-repeat top left;
      position: absolute;
      top: 75px;
      right: -18px;
      @include mqXS {
        top: auto;
        right: 0;
        left: 0;
        bottom: -25px;
        margin: auto;
        transform: rotate(90deg);
        background-size: 100% auto;
        width: 18px;
        height: 55px;
      }
    }
    @include mqSM {
      &:nth-of-type(3n),
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
    @include mqXS {
      &:last-of-type {
        padding-bottom: 0;
        &:after {
          display: none;
        }
      }
    }
    .thumb {
      margin-bottom: 20px;
      @include mqXS {
        width: 40%;
      }
    }
    p {
      line-height: 1.5;
      @include mqXS {
        width: 60%;
        padding-left: 4%;
        padding-top: 35px;
      }
      .sml {
        font-size: 1.3rem;
      }
    }
  }
}

.uv-list {
  li {
    padding: 0 10px;
    text-align: center;
    margin-bottom: 30px;
    a {
      display: block;
      @include commonHover;
    }
    .thumb {
      margin-bottom: 15px;
      width: 100%;
      height: 300px;
    }
    p {
      display: inline-block;
      background: $clr01;
      color: #fff;
      line-height: 1;
      padding: 5px 15px;
      border-radius: 40px;
    }
  }
}

.doctor-cont {
  padding: 0;
  section {
    padding: 0;
  }
  p {
    margin-bottom: 20px;
  }
}

.common-tbl {
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  margin-bottom: 40px;
  th,
  td {
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    @include mqXS {
      padding: 5px;
      font-size: 1rem;
    }
  }
}

.faq-cont {
  dl {
    dt {
      border: 1px solid $clr01;
      line-height: 1.3;
      padding: 10px 40px 10px 10px;
      font-weight: bold;
      font-size: 1.8rem;
      margin-top: 10px;
      color: $clr01;
      position: relative;
      cursor: pointer;
      @include mqXS {
        font-size: 1.4rem;
      }
      &:before {
        content: "Q.";
      }
      &:after {
        content: "＋";
        line-height: 1;
        font-size: 2.4rem;
        position: absolute;
        right: 20px;
        top: calc(50% - 10px);
        transition: 0.4s;
        @include mqXS {
          right: 10px;
          font-size: 1.8rem;
        }
      }
      &.active {
        &:after {
          transform: rotate(405deg);
        }
      }
    }
    dd {
      display: none;
      padding: 20px 0;
    }
  }
}

.faq-cont01 {
  margin-bottom: 40px;
  .sml {
    font-size: 1.2rem;
  }
}

.faq-nav {
  ul {
    margin-bottom: 40px;
    li {
      text-align: center;
      padding: 10px;
      @include mqXS {
        padding: 10px 2px;
      }
      a {
        display: block;
        border: 1px solid #444;
        padding: 10px;
        @include commonHover;
        @include mqXS {
          padding: 10px 5px;
        }
      }
    }
  }
}

.faq-tbl01 {
  padding: 20px 0;
  .tbl-l,
  .tbl-r {
    width: 48%;
  }
  table {
    width: 100%;
    margin-bottom: 20px;
  }
  th {
    background: $clr01;
    color: #fff;
  }
}

.components {
  border: 2px solid $clr01;
  padding: 10px;
  .trigger {
    color: $clr01;
    font-weight: bold;
    position: relative;
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      border-bottom: 1px solid #444;
      border-right: 1px solid #444;
      position: absolute;
      right: 10px;
      top: calc(50% - 5px);
      transform: rotate(45deg);
    }
    &.active {
      &:after {
        transform: rotate(225deg);
      }
    }
  }
  .compo-inner {
    padding-top: 20px;
    display: none;
    font-size: 1.3rem;
  }
}

.common-cont {
  section {
    margin-bottom: 40px;
    p {
      margin-bottom: 20px;
    }
  }
}

.guide-list {
  li {
    padding: 0 20px;
    @include mqXS {
      padding: 0 0 0px 0;
    }
  }
}

.tc {
  text-align: center;
}

.pd-block {
  img {
    max-width: 100%;
  }
  * {
    box-sizing: border-box;
  }
  ul,
  li,
  dl,
  dd,
  dt {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  a {
    color: #111;
  }
  h3 {
    font-size: 1.8rem;
    line-height: 1.8;
    color: #0d472a;
  }
  .teiki_notice {
    h3 {
      background: #0d472a;
      color: #fff;
      margin-bottom: 15px;
      padding: 6px 10px;
    }
    .teiki_tbl {
      @include flex;
      flex-wrap: wrap;
      .dt {
        color: #0d472a;
        font-weight: bold;
        width: 150px;
        @include mqXS {
          width: 100%;
          line-height: 1;
          margin-bottom: 5px;
        }
        &:before {
          content: "●";
        }
      }
      .dd {
        width: calc(100% - 150px);
        @include mqXS {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
  .bold {
    font-weight: bold;
  }
  .pd-green-list {
    margin-bottom: 30px;
    li {
      background: #0d472a;
      color: #fff;
      width: 32%;
      padding: 20px 20px 0 20px;
      margin-bottom: 20px;
      @include flex;
      justify-content: space-between;
      align-items: center;
      @include mqXS {
        width: 100%;
        padding: 10px 10px 0 10px;
      }
      > div {
        width: 49%;
        font-size: 1.5rem;
        line-height: 1.8;
        &:first-of-type {
          text-align: center;
          p.bold {
            text-align: left;
            font-size: 24px;
            line-height: 1;
          }
        }
      }
      &:nth-of-type(1) {
        > div {
          &:first-of-type {
            p.bold {
              padding-left: 22px;
            }
          }
        }
      }
      &:nth-of-type(2) {
        > div {
          &:first-of-type {
            p.bold {
              padding-left: 33px;
            }
          }
        }
      }
      &:nth-of-type(3) {
        > div {
          &:first-of-type {
            p.bold {
              padding-left: 22px;
            }
          }
        }
      }
    }
  }
  &.top-category {
    @include mqXS {
      ul.half-width {
        flex-direction: column;
        li {
          width: 100%;
        }
        p {
          display: block;
        }
      }
    }
  }
}

.pd-kv {
  margin-top: $hh;
  margin-bottom: 40px;
  padding-top: 40px;
  @include mqXS {
    margin-top: 0;
    margin-bottom: 40px;
  }
}

.pd-main {
  @include flex;
  justify-content: space-between;
  @include mqXS {
    flex-direction: column;
  }
  .slider-cont {
    .slider-thumb {
      @include flex;
      flex-wrap: wrap;
      .slide {
        width: 18%;
        margin: 0 1% 10px 1%;
        cursor: pointer;
      }
    }
  }
  .pd-main-l {
    width: 40%;
    @include mqXS {
      width: 100%;
    }
  }
  .pd-main-r {
    width: 56%;
    @include mqXS {
      width: 100%;
    }
    h2 {
      color: #fff;
      background: $clr01;
      font-size: 2rem;
      padding: 10px;
      margin-bottom: 20px;
      line-height: 1;
    }
    h3 {
      font-size: 2rem;
      color: $clr01;
      border-left: 5px solid $clr01;
      line-height: 1.5;
      padding: 5px 0 5px 10px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 10px;
      &.strike {
        font-size: 1.3rem;
        margin-bottom: 0;
        line-height: 1;
        small {
          position: relative;
          &:before {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            top: 50%;
            left: 0;
            background: #333;
          }
        }
      }
      &.price {
        font-size: 2rem;
        color: $clr03;
        span {
          font-size: 2.4rem;
        }
      }
      &.sml {
        font-size: 1.4rem;
      }
    }
    b {
      color: $clr01;
      font-weight: bold;
    }
    .teiki-exp {
      border: 1px solid $clr01;
      padding: 15px;
      margin-bottom: 20px;
      font-size: 1.4rem;
      @include flex;
      flex-wrap: wrap;
      @include mqXS {
        flex-direction: column;
      }
      dt {
        width: 150px;
        font-weight: bold;
        color: $clr01;
        @include mqXS {
          width: 100%;
        }
      }
      dd {
        width: calc(100% - 150px);
        @include mqXS {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    .pd-tbl {
      table {
        border-top: 1px solid #ddd;
        border-right: 1px solid #ddd;
        width: 100%;
        th,
        td {
          padding: 10px;
          border-bottom: 1px solid #ddd;
          border-left: 1px solid #ddd;
          text-align: left;
        }
        th {
          background: #f1f1f1;
          font-weight: bold;
          text-align: center;
          &:nth-of-type(2) {
            border-left: 1px solid #ccc;
          }
        }
      }
    }
    .cart-area {
      padding: 20px 0;
      margin-bottom: 20px;
      > div {
        margin-bottom: 10px;
      }
      select {
        margin-left: 10px;
        font-size: 1.4rem;
        padding: 5px;
        box-sizing: border-box;
      }
      .btn {
        margin-top: 10px;
        appearance: none;
        background: $clr03;
        color: #fff;
        line-height: 1;
        padding: 15px 30px;
        font-size: 2rem;
        outline: none;
        border: none;
        border-radius: 6px;
        cursor: pointer;
      }
      .select-num-box {
        margin-bottom: 20px;
      }
      .radio-list {
        margin-bottom: 20px;
        li {
          border-bottom: 1px solid #eee;
          padding: 5px 0;
          font-size: 1.4rem;
          label {
            @include flex;
            align-items: center;
            input {
              margin-right: 10px;
            }
          }
        }
      }
      .select-list {
        margin-bottom: 20px;
      }
    }
    .trigger {
      font-size: 2rem;
      font-weight: bold;
      border: 2px solid $clr01;
      padding: 5px 10px;
      position: relative;
      color: $clr01;
      margin-bottom: 20px;
      &.active {
        &:after {
          content: "ー";
          right: 14px;
        }
      }
      &:after {
        content: "＋";
        position: absolute;
        right: 15px;
        top: calc(50% - 6px);
        color: #fff;
        line-height: 1;
        font-size: 1.3rem;
      }
      &:before {
        content: "";
        position: absolute;
        right: 10px;
        top: calc(50% - 10px);
        width: 20px;
        height: 20px;
        background: $clr01;
        border-radius: 100%;
      }
    }
    .close {
      display: none;
    }
  }
}

.pd-under {
  border-top: 1px solid $clr01;
  padding-top: 40px;
}

.pd-block {
  h3 {
    font-weight: bold;
  }
}

.option-images {
  @include flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  li {
    width: 23%;
    padding: 1%;
    margin: 0 1% 5px 1%;
    box-sizing: border-box;
    border: 2px solid transparent;
    border-radius: 10px;
    transition: 0.3s;
    &.active,
    &:hover {
      border: 2px solid $clr01;
      cursor: pointer;
    }
    small {
      font-size: 1.3rem;
      line-height: 1.3;
      display: block;
    }
  }
}

.pd-dl {
  margin: 0 10px;
  border: 1px solid #ddd;
  dt {
    padding: 10px;
    background: #f1f1f1;
    font-weight: bold;
  }
  dd {
    border-top: 1px solid #ddd;
    padding: 10px;
  }
}

.products-list {
  @include flex;
  flex-wrap: wrap;
  li {
    width: 23%;
    margin: 0 1% 40px 1%;
    position: relative;
    display: none;
    @include mqXS {
      width: 46%;
      margin: 0 2% 20px 2%;
    }
    a {
      display: block;
      padding: 0 0 40px 0;
      height: 100%;
      @include mqXS {
        padding: 0 0 60px 0;
      }
      @include mqSM {
        transition: 0.4s;
        &:hover {
          box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        }
      }
    }
    h3 {
      padding: 10px;
      line-height: 1.5;
      font-weight: bold;
      @include mqXS {
        padding: 10px 0 0 0;
      }
    }
    .price-box {
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      padding: 0 10px;
      @include flex;
      @include mqXS {
        flex-direction: column;
        padding: 0;
      }
      p {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
  }
}

.products-intro {
  display: none;
  font-size: 1.8rem;
  @include mqXS {
    font-size: 1.4rem;
  }
  .btn-more {
    @include mqXS {
      &:hover {
        span {
          color: #9b9b9b;
        }
      }
    }
    .close {
      display: none;
    }
    .open {
      display: inline-block;
    }
    &.active {
      .close {
        display: inline-block;
      }
      .open {
        display: none;
      }
    }
  }
  .products-intro-box {
    display: none;
    .intro-block {
      padding: 20px 0;
      @include flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      @include mqXS {
        flex-direction: column;
      }
      &:nth-of-type(2n) {
        flex-direction: row;
        @include mqXS {
          flex-direction: column;
        }
      }
      .thumb {
        width: 36%;
        @include mqXS {
          width: 100%;
          margin-bottom: 20px;
        }
        small {
          font-size: 1.4rem;
          padding-top: 5px;
          font-style: italic;
          color: #666;
          @include mqXS {
            font-size: 1.2rem;
          }
        }
      }
      p {
        width: 60%;
        text-align: justify;
        line-height: 2;
        font-size: 1.7rem;
        @include mqXS {
          width: 100%;
          font-size: 1.4rem;
        }
      }
    }
  }
}

.w20 {
  width: 20%;
}

.w40 {
  width: 40%;
}

.sml {
  font-size: 1.4rem;
}
.italic {
  font-style: italic;
}
