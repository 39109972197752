@charset "UTF-8";
/* ===================================================================

scss core settings

=================================================================== */
/* ===================================================================

reset style

=================================================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  letter-spacing: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* ===================================================================

util style

=================================================================== */
/* text */
.tl {
  text-align: left;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

/* float */
.fl {
  float: left;
}

.fr {
  float: right;
}

/* clear */
.clear {
  clear: both;
}

.clear_l {
  clear: left;
}

.clear_r {
  clear: right;
}

/* clearfix */
.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

/* box center */
.center {
  margin-left: auto;
  margin-right: auto;
}

/* Font-Size */
.f10 {
  font-size: 10px;
}

.f11 {
  font-size: 11px;
}

.f12 {
  font-size: 12px;
}

.f13 {
  font-size: 13px;
}

.f14 {
  font-size: 14px;
}

.f15 {
  font-size: 15px;
}

.f16 {
  font-size: 16px;
}

.f17 {
  font-size: 17px;
}

.f18 {
  font-size: 18px;
}

.f19 {
  font-size: 19px;
}

.f20 {
  font-size: 20px;
}

.f21 {
  font-size: 21px;
}

.f22 {
  font-size: 22px;
}

.f23 {
  font-size: 23px;
}

.f24 {
  font-size: 24px;
}

.f25 {
  font-size: 25px;
}

.f26 {
  font-size: 26px;
}

.f27 {
  font-size: 27px;
}

.f28 {
  font-size: 28px;
}

.f29 {
  font-size: 29px;
}

.f30 {
  font-size: 30px;
}

.f31 {
  font-size: 31px;
}

.f32 {
  font-size: 32px;
}

.f33 {
  font-size: 33px;
}

.f34 {
  font-size: 34px;
}

.f35 {
  font-size: 35px;
}

.f36 {
  font-size: 36px;
}

.f37 {
  font-size: 37px;
}

.f38 {
  font-size: 38px;
}

.f39 {
  font-size: 39px;
}

.f40 {
  font-size: 40px;
}

/* line-height */
.lh10 {
  line-height: 10px;
}

.lh11 {
  line-height: 11px;
}

.lh12 {
  line-height: 12px;
}

.lh13 {
  line-height: 13px;
}

.lh14 {
  line-height: 14px;
}

.lh15 {
  line-height: 15px;
}

.lh16 {
  line-height: 16px;
}

.lh17 {
  line-height: 17px;
}

.lh18 {
  line-height: 18px;
}

.lh19 {
  line-height: 19px;
}

.lh20 {
  line-height: 20px;
}

.lh21 {
  line-height: 21px;
}

.lh22 {
  line-height: 22px;
}

.lh23 {
  line-height: 23px;
}

.lh24 {
  line-height: 24px;
}

.lh25 {
  line-height: 25px;
}

.lh26 {
  line-height: 26px;
}

.lh27 {
  line-height: 27px;
}

.lh28 {
  line-height: 28px;
}

.lh29 {
  line-height: 29px;
}

.lh30 {
  line-height: 30px;
}

.lh31 {
  line-height: 31px;
}

.lh32 {
  line-height: 32px;
}

.lh33 {
  line-height: 33px;
}

.lh34 {
  line-height: 34px;
}

.lh35 {
  line-height: 35px;
}

.lh36 {
  line-height: 36px;
}

.lh37 {
  line-height: 37px;
}

.lh38 {
  line-height: 38px;
}

.lh39 {
  line-height: 39px;
}

.lh40 {
  line-height: 40px;
}

/* Margin-Top & Padding-Top */
.mt00 {
  margin-top: 0px;
}

.pt00 {
  padding-top: 0px;
}

.mt05 {
  margin-top: 5px;
}

.pt05 {
  padding-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.pt10 {
  padding-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.pt15 {
  padding-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.pt20 {
  padding-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.pt25 {
  padding-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.pt30 {
  padding-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.pt35 {
  padding-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.pt40 {
  padding-top: 40px;
}

.mt45 {
  margin-top: 45px;
}

.pt45 {
  padding-top: 45px;
}

.mt50 {
  margin-top: 50px;
}

.pt50 {
  padding-top: 50px;
}

.mt55 {
  margin-top: 55px;
}

.pt55 {
  padding-top: 55px;
}

.mt60 {
  margin-top: 60px;
}

.pt60 {
  padding-top: 60px;
}

.mt65 {
  margin-top: 65px;
}

.pt65 {
  padding-top: 65px;
}

.mt70 {
  margin-top: 70px;
}

.pt70 {
  padding-top: 70px;
}

.mt75 {
  margin-top: 75px;
}

.pt75 {
  padding-top: 75px;
}

.mt80 {
  margin-top: 80px;
}

.pt80 {
  padding-top: 80px;
}

.mt85 {
  margin-top: 85px;
}

.pt85 {
  padding-top: 85px;
}

.mt90 {
  margin-top: 90px;
}

.pt90 {
  padding-top: 90px;
}

.mt95 {
  margin-top: 95px;
}

.pt95 {
  padding-top: 95px;
}

.mt100 {
  margin-top: 100px;
}

.pt100 {
  padding-top: 100px;
}

/* Margin-Right & Padding-Right */
.mr00 {
  margin-right: 0px;
}

.pr00 {
  padding-right: 0px;
}

.mr05 {
  margin-right: 5px;
}

.pr05 {
  padding-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.pr10 {
  padding-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.pr15 {
  padding-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.pr20 {
  padding-right: 20px;
}

.mr25 {
  margin-right: 25px;
}

.pr25 {
  padding-right: 25px;
}

.mr30 {
  margin-right: 30px;
}

.pr30 {
  padding-right: 30px;
}

.mr35 {
  margin-right: 35px;
}

.pr35 {
  padding-right: 35px;
}

.mr40 {
  margin-right: 40px;
}

.pr40 {
  padding-right: 40px;
}

.mr45 {
  margin-right: 45px;
}

.pr45 {
  padding-right: 45px;
}

.mr50 {
  margin-right: 50px;
}

.pr50 {
  padding-right: 50px;
}

.mr55 {
  margin-right: 55px;
}

.pr55 {
  padding-right: 55px;
}

.mr60 {
  margin-right: 60px;
}

.pr60 {
  padding-right: 60px;
}

.mr65 {
  margin-right: 65px;
}

.pr65 {
  padding-right: 65px;
}

.mr70 {
  margin-right: 70px;
}

.pr70 {
  padding-right: 70px;
}

.mr75 {
  margin-right: 75px;
}

.pr75 {
  padding-right: 75px;
}

.mr80 {
  margin-right: 80px;
}

.pr80 {
  padding-right: 80px;
}

.mr85 {
  margin-right: 85px;
}

.pr85 {
  padding-right: 85px;
}

.mr90 {
  margin-right: 90px;
}

.pr90 {
  padding-right: 90px;
}

.mr95 {
  margin-right: 95px;
}

.pt95 {
  padding-right: 95px;
}

.mr100 {
  margin-right: 100px;
}

.pr100 {
  padding-right: 100px;
}

/* Margin-Bottom & Padding-Bottom */
.mb00 {
  margin-bottom: 0px;
}

.pb00 {
  padding-bottom: 0px;
}

.mb05 {
  margin-bottom: 5px;
}

.pb05 {
  padding-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.pb10 {
  padding-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.pb15 {
  padding-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.pb20 {
  padding-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.pb25 {
  padding-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.pb30 {
  padding-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.pb35 {
  padding-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.pb40 {
  padding-bottom: 40px;
}

.mb45 {
  margin-bottom: 45px;
}

.pb45 {
  padding-bottom: 45px;
}

.mb50 {
  margin-bottom: 50px;
}

.pb50 {
  padding-bottom: 50px;
}

.mb55 {
  margin-bottom: 55px;
}

.pb55 {
  padding-bottom: 55px;
}

.mb60 {
  margin-bottom: 60px;
}

.pb60 {
  padding-bottom: 60px;
}

.mb65 {
  margin-bottom: 65px;
}

.pb65 {
  padding-bottom: 65px;
}

.mb70 {
  margin-bottom: 70px;
}

.pb70 {
  padding-bottom: 70px;
}

.mb75 {
  margin-bottom: 75px;
}

.pb75 {
  padding-bottom: 75px;
}

.mb80 {
  margin-bottom: 80px;
}

.pb80 {
  padding-bottom: 80px;
}

.mb85 {
  margin-bottom: 85px;
}

.pb85 {
  padding-bottom: 85px;
}

.mb90 {
  margin-bottom: 90px;
}

.pb90 {
  padding-bottom: 90px;
}

.mb95 {
  margin-bottom: 95px;
}

.pt95 {
  padding-bottom: 95px;
}

.mb100 {
  margin-bottom: 100px;
}

.pb100 {
  padding-bottom: 100px;
}

/* Margin-Left & Padding-Left */
.ml00 {
  margin-left: 0px;
}

.pl00 {
  padding-left: 0px;
}

.ml05 {
  margin-left: 5px;
}

.pl05 {
  padding-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.pl10 {
  padding-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.pl15 {
  padding-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.pl20 {
  padding-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

.pl25 {
  padding-left: 25px;
}

.ml30 {
  margin-left: 30px;
}

.pl30 {
  padding-left: 30px;
}

.ml35 {
  margin-left: 35px;
}

.pl35 {
  padding-left: 35px;
}

.ml40 {
  margin-left: 40px;
}

.pl40 {
  padding-left: 40px;
}

.ml45 {
  margin-left: 45px;
}

.pl45 {
  padding-left: 45px;
}

.ml50 {
  margin-left: 50px;
}

.pl50 {
  padding-left: 50px;
}

.ml55 {
  margin-left: 55px;
}

.pl55 {
  padding-left: 55px;
}

.ml60 {
  margin-left: 60px;
}

.pl60 {
  padding-left: 60px;
}

.ml65 {
  margin-left: 65px;
}

.pl65 {
  padding-left: 65px;
}

.ml70 {
  margin-left: 70px;
}

.pl70 {
  padding-left: 70px;
}

.ml75 {
  margin-left: 75px;
}

.pl75 {
  padding-left: 75px;
}

.ml80 {
  margin-left: 80px;
}

.pl80 {
  padding-left: 80px;
}

.ml85 {
  margin-left: 85px;
}

.pb85 {
  padding-left: 85px;
}

.ml90 {
  margin-left: 90px;
}

.pl90 {
  padding-left: 90px;
}

.ml95 {
  margin-left: 95px;
}

.pt95 {
  padding-left: 95px;
}

.ml100 {
  margin-left: 100px;
}

.pl100 {
  padding-left: 100px;
}

/* ===================================================================

init style

=================================================================== */
html {
  font-size: 62.5%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
  font-weight: 500;
  font-size: 10px;
  font-size: 1rem;
  line-height: 1.7;
  font-feature-settings: "palt";
  letter-spacing: 0.8px;
  color: #666;
  background: #fff;
}

_:lang(x)::-ms-backdrop,
.selector {
  font-family: "Segoe UI", Meiryo, sans-serif;
}

b,
strong,
.bold {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI semibold", "Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic", "Segoe UI", Verdana, Meiryo, sans-serif;
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro W3", "メイリオ", Meiryo, Verdana, sans-serif;
    width: 100%;
    overflow-x: hidden;
  }
}

a {
  text-decoration: none;
  color: #666;
}

a:hover {
  text-decoration: none;
  color: #666;
}

img {
  vertical-align: bottom;
  max-width: 100%;
}

select,
input,
textarea,
button {
  vertical-align: middle;
}

#wrap {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  color: #666;
  overflow-x: hidden;
  font-size: 1.6rem;
}

@media (max-width: 767px) {
  #wrap {
    font-size: 1.3rem;
  }
}

main {
  display: block;
  padding-top: 0;
}

@media (max-width: 767px) {
  main {
    padding-top: 57px;
  }
}

/* ===================================================================

common style

=================================================================== */
.box {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 767px) {
  .box {
    max-width: 100%;
    padding: 0 5%;
    box-sizing: border-box;
  }
}

.box-narrow {
  width: 850px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 767px) {
  .box-narrow {
    max-width: 100%;
    padding: 0 8%;
    box-sizing: border-box;
  }
}

.box-wide {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

.inner-box {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 767px) {
  .inner-box {
    width: 88.7%;
  }
}

.full-width {
  width: 100%;
}

.fz-large {
  font-size: 2rem;
}

@media (max-width: 767px) {
  .fz-large {
    font-size: 1.6rem;
  }
}

.tj {
  text-align: justify;
}

.lh1 {
  line-height: 1;
}

.lh1-1 {
  line-height: 1.1;
}

.lh1-2 {
  line-height: 1.2;
}

.lh1-3 {
  line-height: 1.3;
}

.lh1-4 {
  line-height: 1.4;
}

.lh1-5 {
  line-height: 1.5;
}

.lh1-6 {
  line-height: 1.6;
}

.lh1-7 {
  line-height: 1.7;
}

.lh1-8 {
  line-height: 1.8;
}

.lh1-9 {
  line-height: 1.9;
}

.lh2 {
  line-height: 2;
}

.ls1 {
  letter-spacing: 0.1em;
}

.ls2 {
  letter-spacing: 0.2em;
}

.ls3 {
  letter-spacing: 0.3em;
}

.ls4 {
  letter-spacing: 0.4em;
}

.ls5 {
  letter-spacing: 0.5em;
}

.common-title01 {
  font-size: 3.4rem;
  line-height: 1.3;
  font-weight: bold;
  color: #00472b;
  letter-spacing: 0.1em;
  margin-bottom: 40px;
  text-align: center;
}

@media (max-width: 767px) {
  .common-title01 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}

.common-title01 span {
  font-weight: lighter;
  font-size: 1.6rem;
  display: block;
  letter-spacing: 0.3em;
}

@media (max-width: 767px) {
  .common-title01 span {
    font-size: 1.2rem;
  }
}

.common-title02 {
  font-size: 2.4rem;
  margin-bottom: 40px;
  text-align: center;
  font-feature-settings: "palt";
  position: relative;
  padding-bottom: 15px;
}

.common-title02:after {
  content: "";
  width: 50px;
  height: 1px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@media (max-width: 767px) {
  .common-title02 {
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
}

.common-title02.tl {
  text-align: left;
}

.common-title03 {
  font-size: 2.8rem;
  line-height: 1.5;
  margin-bottom: 30px;
  color: #b1282e;
  font-weight: bold;
  letter-spacing: 0.08em;
}

@media (max-width: 767px) {
  .common-title03 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}

.common-title04 {
  background: #b1282e;
  text-align: center;
  font-size: 2.8rem;
  color: #fff;
  font-weight: bold;
  padding: 15px 0;
  margin-bottom: 30px;
  line-height: 1.3;
}

@media (max-width: 767px) {
  .common-title04 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
}

.common-title04.green-bg {
  background: #00472b;
}

.common-title05 {
  font-size: 2rem;
  color: #00472b;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.3;
}

@media (max-width: 767px) {
  .common-title05 {
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .tc.sp-tl {
    text-align: left;
  }
}

.mt-40 {
  margin-top: -40px;
}

a.btn-common {
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  line-height: 1;
  background: #fff;
  color: #666;
  font-size: 1.8rem;
}

@media (max-width: 767px) {
  a.btn-common {
    font-weight: bold;
  }
}

@media (min-width: 768px) {
  a.btn-common {
    transition: 0.4s;
    position: relative;
  }
  a.btn-common:before, a.btn-common:after {
    content: "";
    position: absolute;
    opacity: 0;
    transform: translateX(5px);
    transition: 0.4s;
  }
  a.btn-common:before {
    width: 20px;
    height: 20px;
    right: 10px;
    top: calc(50% - 10px);
    background: #666;
    border-radius: 100%;
  }
  a.btn-common:after {
    width: 6px;
    height: 6px;
    right: 18px;
    top: calc(50% - 3px);
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: translateX(5px) rotate(-45deg);
  }
  a.btn-common:hover:before {
    opacity: 1;
    transform: translateX(0px);
  }
  a.btn-common:hover:after {
    opacity: 1;
    transform: translateX(0px) rotate(-45deg);
    transition: 0.4s 0.1s;
  }
}

a.btn-white {
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  line-height: 1;
  background: #fff;
  color: #666;
}

@media (max-width: 767px) {
  a.btn-white {
    font-weight: bold;
  }
}

@media (min-width: 768px) {
  a.btn-white {
    transition: 0.4s;
    position: relative;
  }
  a.btn-white:before, a.btn-white:after {
    content: "";
    position: absolute;
    opacity: 0;
    transform: translateX(5px);
    transition: 0.4s;
  }
  a.btn-white:before {
    width: 20px;
    height: 20px;
    right: 10px;
    top: calc(50% - 10px);
    background: #666;
    border-radius: 100%;
  }
  a.btn-white:after {
    width: 6px;
    height: 6px;
    right: 18px;
    top: calc(50% - 3px);
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: translateX(5px) rotate(-45deg);
  }
  a.btn-white:hover:before {
    opacity: 1;
    transform: translateX(0px);
  }
  a.btn-white:hover:after {
    opacity: 1;
    transform: translateX(0px) rotate(-45deg);
    transition: 0.4s 0.1s;
  }
}

a.btn-dark {
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  line-height: 1;
  background: #666;
  color: #fff;
}

@media (max-width: 767px) {
  a.btn-dark {
    font-weight: bold;
  }
}

@media (min-width: 768px) {
  a.btn-dark {
    transition: 0.4s;
    position: relative;
  }
  a.btn-dark:before, a.btn-dark:after {
    content: "";
    position: absolute;
    opacity: 0;
    transform: translateX(5px);
    transition: 0.4s;
  }
  a.btn-dark:before {
    width: 20px;
    height: 20px;
    right: 10px;
    top: calc(50% - 10px);
    background: #fff;
    border-radius: 100%;
  }
  a.btn-dark:after {
    width: 6px;
    height: 6px;
    right: 18px;
    top: calc(50% - 3px);
    border-bottom: 1px solid #666;
    border-right: 1px solid #666;
    transform: translateX(5px) rotate(-45deg);
  }
  a.btn-dark:hover:before {
    opacity: 1;
    transform: translateX(0px);
  }
  a.btn-dark:hover:after {
    opacity: 1;
    transform: translateX(0px) rotate(-45deg);
    transition: 0.4s 0.1s;
  }
}

a.btn-pink {
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  line-height: 1;
  background: #00472b;
  color: #fff;
}

@media (max-width: 767px) {
  a.btn-pink {
    font-weight: bold;
  }
}

@media (min-width: 768px) {
  a.btn-pink {
    transition: 0.4s;
    position: relative;
  }
  a.btn-pink:before, a.btn-pink:after {
    content: "";
    position: absolute;
    opacity: 0;
    transform: translateX(5px);
    transition: 0.4s;
  }
  a.btn-pink:before {
    width: 20px;
    height: 20px;
    right: 10px;
    top: calc(50% - 10px);
    background: #fff;
    border-radius: 100%;
  }
  a.btn-pink:after {
    width: 6px;
    height: 6px;
    right: 18px;
    top: calc(50% - 3px);
    border-bottom: 1px solid #00472b;
    border-right: 1px solid #00472b;
    transform: translateX(5px) rotate(-45deg);
  }
  a.btn-pink:hover:before {
    opacity: 1;
    transform: translateX(0px);
  }
  a.btn-pink:hover:after {
    opacity: 1;
    transform: translateX(0px) rotate(-45deg);
    transition: 0.4s 0.1s;
  }
}

a.size-sml {
  padding: 14px 30px;
  font-size: 2rem;
}

@media (min-width: 768px) {
  a.size-sml:before {
    right: 5px;
  }
  a.size-sml:after {
    right: 13px;
  }
}

@media (max-width: 767px) {
  a.size-sml {
    padding: 14px 20px;
    font-size: 1.2rem;
  }
}

a.size-xsml {
  font-size: 1.6rem;
  padding: 15px 20px;
}

@media (max-width: 767px) {
  a.size-xsml {
    font-size: 1.4rem;
  }
}

a.size-medium {
  font-size: 3rem;
  padding: 15px 0;
}

@media (min-width: 768px) {
  a.size-medium:before {
    width: 30px;
    height: 30px;
    top: calc(50% - 15px);
  }
  a.size-medium:after {
    width: 12px;
    height: 12px;
    top: calc(50% - 6px);
    right: 23px;
  }
}

@media (max-width: 767px) {
  a.size-medium {
    font-size: 1.8rem;
  }
}

a.size-large {
  font-size: 3.6rem;
  padding: 15px 30px;
}

@media (min-width: 768px) {
  a.size-large:before {
    width: 36px;
    height: 36px;
    top: calc(50% - 18px);
  }
  a.size-large:after {
    width: 14px;
    height: 14px;
    top: calc(50% - 7px);
    right: 24px;
  }
}

@media (max-width: 767px) {
  a.size-large {
    font-size: 1.8rem;
  }
}

.btn-more {
  cursor: pointer;
  padding: 15px 0;
  width: 200px;
  font-size: 1.4rem;
  line-height: 1;
  color: #9b9b9b;
  border: 1px solid #9b9b9b;
  overflow: hidden;
  display: inline-block;
}

@media (max-width: 767px) {
  .btn-more {
    padding: 15px 0;
    font-size: 1.2rem;
    border-width: 1px;
  }
}

@media (min-width: 768px) {
  .btn-more {
    transition: 0.4s 0.1s;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  .btn-more:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #696969;
    position: absolute;
    z-index: -1;
    left: -100%;
    top: 0;
    transition: 0.4s cubic-bezier(1, 0.05, 0.37, 1.01);
  }
  .btn-more:hover {
    color: #fff;
  }
  .btn-more:hover:before {
    left: 0;
    width: 105%;
  }
}

.btn-more:before {
  z-index: 0;
}

.btn-more span {
  transition: 0.4s;
  position: relative;
  z-index: 1;
}

.btn-more:hover span {
  color: #fff;
}

.btn-more.c-white {
  color: #fff;
  border: 1px solid #fff;
}

.btn-more.c-green {
  color: #fff;
  border: 1px solid #00472b;
  background: #00472b;
}

.btn-more.btn-wider {
  width: 400px;
}

@media (max-width: 767px) {
  .btn-more.btn-wider {
    width: 100%;
  }
}

.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
}

.fd-r {
  flex-direction: row-reverse;
}

.fd-cr {
  flex-direction: column-reverse;
}

.fd-c {
  flex-direction: column;
}

.jc-c {
  justify-content: center;
}

.jc-sb {
  justify-content: space-between;
}

.jc-fe {
  justify-content: flex-end;
}

.ai-c {
  align-items: center;
}

.ai-fe {
  align-items: flex-end;
}

@media (max-width: 767px) {
  .sp-flex-wrap {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .sp-fd-c {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .sp-fd-cr {
    flex-direction: column-reverse;
  }
}

.col2-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap;
}

.col2-list li {
  width: 50%;
}

.col3-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap;
}

.col3-list li {
  width: 33.33%;
}

.col4-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap;
}

.col4-list li {
  width: 25%;
}

.col5-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap;
}

.col5-list li {
  width: 20%;
}

@media (max-width: 767px) {
  .sp-col2-list li {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .sp-col1-list li {
    width: 100%;
  }
}

.anm {
  transition: 1s;
}

.anm.fading {
  opacity: 0;
}

.anm.to-up {
  transform: translateY(20px);
}

.anm.to-down {
  transform: translateY(-20px);
}

.anm.to-right {
  transform: translateX(-10px);
}

.anm.to-left {
  transform: translateX(10px);
}

.anm.to-left-zoomin {
  transform: translateX(10px) scale(0.9);
  transform-origin: right bottom;
}

.anm.blur {
  filter: blur(10px);
}

.anm.ease-back {
  transition-duration: 2s;
  transition-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.anm.ease-back.to-up {
  transform: translateY(40px);
}

.anm.is-show.fading {
  opacity: 1;
}

.anm.is-show.to-up {
  transform: translateY(0px);
}

.anm.is-show.to-down {
  transform: translateY(0px);
}

.anm.is-show.to-right {
  transform: translateX(0px);
}

.anm.is-show.to-left {
  transform: translateX(0px);
}

.anm.is-show.to-left-zoomin {
  transition: 2s 0.4s;
  transform: translateX(0px) scale(1);
}

.anm.is-show.blur {
  filter: blur(0px);
}

.anm.is-show.delay1 {
  transition-delay: 0.2s;
}

.anm.is-show.delay2 {
  transition-delay: 0.4s;
}

.anm.is-show.ease-back {
  transition-duration: 2s;
  transition-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.anm.is-show.ease-back.to-up {
  transform: translateY(0px);
}

.red {
  color: #ae1b22;
}

.lime {
  color: #15ae67;
}

.pink {
  color: #c8161e;
}

img.ofi {
  object-fit: cover;
  object-position: center;
  font-family: "object-fit: cover;";
  width: 100%;
  height: 100%;
}

.pc {
  display: block !important;
}

@media (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

.sp {
  display: none !important;
}

@media (max-width: 767px) {
  .sp {
    display: block !important;
  }
}

/* Loading */
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  width: 40px;
  height: 40px;
  z-index: 99999;
}

.spinner span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
}

.spinner.type1 {
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: #333 rgba(30, 30, 30, 0.12) rgba(30, 30, 30, 0.12);
  animation: spinner1_1 1.5s infinite linear forwards;
}

.spinner.type1 span {
  animation: spinner_loading_text 1.5s infinite linear forwards reverse;
}

.slider-full-height {
  height: 100%;
}

.slider-full-height .slick-list {
  height: 100%;
}

.slider-full-height .slick-list .slick-track {
  height: 100%;
}

.slider-full-height .slick-list .slick-track .slick-slide {
  height: 100%;
}

.slider-full-height .slick-list .slick-track .slick-slide > div {
  height: 100%;
}

.slider-full-height .slick-list .slick-track .slick-slide > div .each-slide {
  height: 100%;
}

.slider-full-height .slick-list .slick-track .slick-slide > div .each-slide > div {
  height: 100%;
}

/* ===================================================================

header style

=================================================================== */
#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  color: #444;
  background: #fff;
  transition: 0.2s;
}

#header.trans {
  background: transparent;
}

#header .head-inner {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px 20px 30px;
}

@media (max-width: 767px) {
  #header .head-inner {
    padding: 15px 4% 15px 4%;
    z-index: 1;
  }
}

#header .head-inner .head-logo {
  margin: 0;
}

@media (max-width: 767px) {
  #header .head-inner .head-logo {
    width: 24%;
  }
}

#header.h-white {
  background: transparent;
}

.hnav {
  position: relative;
  z-index: 1001;
}

@media (max-width: 767px) {
  .hnav {
    display: none;
  }
}

.hnav ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 50px;
}

.hnav ul li {
  margin-left: 20px;
  font-size: 1.4rem;
  line-height: 1;
}

@media (max-width: 767px) {
  .hnav ul li {
    margin-left: 10px;
    font-size: 1.3rem;
  }
}

.hnav ul li.h-tel {
  font-size: 2.4rem;
  font-weight: bold;
}

.hnav ul li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  align-items: center;
}

.hnav ul li a img {
  margin-left: 5px;
}

@media (min-width: 768px) {
  .hnav ul li a {
    transition: 0.2s;
  }
  .hnav ul li a:hover {
    opacity: 0.7;
  }
}

.nav-btn {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  width: 25px;
  height: 18px;
  z-index: 10;
  margin: auto;
  cursor: pointer;
  z-index: 10001;
}

@media (max-width: 767px) {
  .nav-btn {
    right: 4%;
    top: 0px;
  }
}

.nav-btn:before {
  content: "";
  width: 25px;
  height: 2px;
  background: #444;
  position: absolute;
  top: 0px;
  right: 0;
  transition: 0.4s;
}

.nav-btn:after {
  content: "";
  width: 25px;
  height: 2px;
  background: #444;
  position: absolute;
  top: 16px;
  right: 0;
  transition: 0.4s;
}

.nav-btn span {
  display: block;
  width: 25px;
  height: 2px;
  background: #444;
  position: absolute;
  top: 8px;
  right: 0;
  transition: 0.4s;
}

.nav-btn.active:before {
  content: "";
  width: 20px;
  height: 2px;
  top: 10px;
  right: 0;
  transform: rotate(45deg);
  background: #444;
}

.nav-btn.active:after {
  content: "";
  width: 20px;
  height: 2px;
  top: 10px;
  right: 0;
  transform: rotate(-45deg);
  background: #444;
}

.nav-btn.active span {
  opacity: 0;
}

.nav-btn.active .close-txt {
  opacity: 1;
  transition: 0.4s 0.2s;
  padding-left: 40px;
}

.gnav {
  background: rgba(0, 71, 43, 0.9);
}

@media (max-width: 767px) {
  .gnav {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    padding-top: 60px;
    transition: 0.2s;
    transform: translateY(-100%);
    overflow: scroll;
  }
  .gnav.on {
    transform: translateY(0%);
  }
}

.gnav > ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  justify-content: space-between;
  color: #fff;
  position: relative;
}

@media (max-width: 767px) {
  .gnav > ul {
    color: #6c6c6c;
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .gnav > ul li {
    width: 100%;
  }
}

.gnav > ul li > a,
.gnav > ul li .gnav-toggle {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 10px 10px;
  line-height: 1;
}

@media (min-width: 768px) {
  .gnav > ul li > a,
  .gnav > ul li .gnav-toggle {
    transition: 0.2s;
  }
  .gnav > ul li > a:hover,
  .gnav > ul li .gnav-toggle:hover {
    background: #1d684a;
    color: #aacec0;
  }
}

@media (max-width: 767px) {
  .gnav > ul li > a,
  .gnav > ul li .gnav-toggle {
    color: #6c6c6c;
    display: block;
    width: 100%;
    border-bottom: 1px solid #ddd;
    font-size: 1.4em;
  }
}

@media (max-width: 767px) {
  .gnav > ul li .gnav-toggle {
    position: relative;
  }
  .gnav > ul li .gnav-toggle:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 1px solid #00472b;
    border-right: 1px solid #00472b;
    position: absolute;
    right: 0;
    bottom: calc(50% - 4px);
    transform: rotate(45deg);
    transition: 0.2s;
  }
}

.gnav .gnav-inner-wrap .gnav-inner {
  overflow: hidden;
  -webkit-animation: hideDown 0.2s ease-out 0s 1 forwards;
  animation: hideDown 0.2s ease-out 0s 1 forwards;
  position: absolute;
  top: 35px;
  padding-top: 10px;
}

@media (max-width: 767px) {
  .gnav .gnav-inner-wrap .gnav-inner {
    display: none;
    position: static;
    padding-top: 0;
  }
}

.gnav .gnav-inner-wrap .gnav-inner ul {
  color: #6c6c6c;
  background: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .gnav .gnav-inner-wrap .gnav-inner ul {
    padding: 0;
    box-shadow: none;
  }
}

.gnav .gnav-inner-wrap .gnav-inner ul li a {
  color: #6c6c6c;
  font-size: 1.6rem;
}

@media (min-width: 768px) {
  .gnav .gnav-inner-wrap .gnav-inner ul li a {
    transition: 0.2s;
    border-bottom: 1px solid transparent;
  }
  .gnav .gnav-inner-wrap .gnav-inner ul li a:hover {
    color: #fff;
  }
}

@media (max-width: 767px) {
  .gnav .gnav-inner-wrap .gnav-inner ul li a {
    font-size: 1.4rem;
    padding-left: 4%;
  }
}

.gnav .gnav-inner-wrap.on .gnav-inner {
  overflow: visible;
  visibility: visible;
  -webkit-animation: showUp 0.2s ease-out 0s 1 forwards;
  animation: showUp 0.2s ease-out 0s 1 forwards;
}

.gnav .gnav-inner-wrap.on.products .gnav-inner {
  width: 100%;
}

@media (max-width: 767px) {
  .gnav .gnav-inner-wrap.on .gnav-toggle:after {
    transform: rotate(225deg);
    bottom: calc(50% - 6px);
  }
}

.gnav .gnav-inner-wrap.products .gnav-inner {
  left: 0;
  width: 100%;
}

.gnav .gnav-inner-wrap.products .gnav-inner ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap;
}

.gnav .gnav-inner-wrap.products .gnav-inner ul li {
  width: 20%;
}

@media (max-width: 767px) {
  .gnav .gnav-inner-wrap.products .gnav-inner ul li {
    width: 50%;
  }
}

.gnav .gnav-inner-wrap.products .gnav-inner ul li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  align-items: center;
}

.gnav .gnav-inner-wrap.products .gnav-inner ul li a .pd-txt {
  padding-left: 10px;
  line-height: 1.3;
}

@media (max-width: 767px) {
  .gnav .gnav-inner-wrap.products .gnav-inner ul li a .pd-txt {
    font-size: 1.2rem;
    display: block;
    width: 75%;
  }
}

@media (max-width: 767px) {
  .gnav .gnav-inner-wrap.products .gnav-inner ul li a .pd-ico {
    width: 25%;
  }
}

.gnav .gnav-inner-wrap.products .gnav-inner ul li a .pd-ico img {
  max-height: 46px;
}

@media (max-width: 767px) {
  .gnav .gnav-inner-wrap.products .gnav-inner ul li a .pd-ico img {
    height: 40px;
  }
}

/* ===================================================================

footer style

=================================================================== */
#footer {
  padding: 40px 0 100px 0;
  background: #1d684a;
  color: #fff;
  position: relative;
  margin-top: 80px;
  text-align: left;
}

@media (max-width: 767px) {
  #footer {
    padding: 40px 0 40px 0;
  }
}

#footer .foot-head {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  #footer .foot-head .flogo {
    width: 80%;
    margin: 0 auto 10px auto;
    text-align: center;
  }
}

#footer .foot-head .fnav01 ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 767px) {
  #footer .foot-head .fnav01 ul {
    justify-content: center;
    padding-top: 10px;
  }
}

#footer .foot-head .fnav01 ul li {
  margin-left: 20px;
}

@media (max-width: 767px) {
  #footer .foot-head .fnav01 ul li {
    margin: 0 10px;
  }
  #footer .foot-head .fnav01 ul li:last-of-type {
    display: none;
  }
}

#footer .foot-nav {
  padding: 50px 0 45px 0;
}

@media (max-width: 767px) {
  #footer .foot-nav {
    padding: 20px 0;
  }
}

#footer .foot-nav .fnav02 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
}

@media (max-width: 767px) {
  #footer .foot-nav .fnav02 {
    display: none;
  }
}

#footer .foot-nav .fnav02 p {
  font-weight: bold;
  margin-bottom: 15px;
  height: 20px;
}

#footer .foot-nav .fnav02 ul li {
  margin-bottom: 5px;
}

#footer .foot-nav .fnav02 ul li a {
  color: #aacec0;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  #footer .foot-nav .fnav02 ul li a {
    transition: 0.4s;
  }
  #footer .foot-nav .fnav02 ul li a:hover {
    opacity: 0.6;
  }
}

#footer .foot-sns {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  #footer .foot-sns {
    margin-bottom: 20px;
  }
}

#footer .foot-sns ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

#footer .foot-sns ul li {
  margin: 0 10px;
}

@media (min-width: 768px) {
  #footer .foot-sns ul li a {
    transition: 0.4s;
  }
  #footer .foot-sns ul li a:hover {
    opacity: 0.6;
  }
}

#footer .foot-address {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  #footer .foot-address {
    font-size: 1.3rem;
  }
}

#footer .foot-copy {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: #fff;
  background: #00472b;
  padding: 10px 0;
}

@media (max-width: 767px) {
  #footer .foot-copy {
    font-size: 1.1rem;
  }
}

.sp-fnav {
  text-align: center;
}

.sp-fnav ul {
  margin-bottom: 20px;
  border-top: 1px solid #aacec0;
}

.sp-fnav ul li a {
  display: block;
  color: #aacec0;
  border-bottom: 1px solid #aacec0;
  padding: 10px 0;
}

/* ===================================================================

index style

=================================================================== */
.top-kv {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 640px;
}

@media (max-width: 767px) {
  .top-kv {
    height: auto;
    height: 495px;
  }
}

.top-kv .kv-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

.top-kv .kv-inner > span {
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .top-kv .kv-inner img.ofi {
    object-position: top;
  }
}

.top-kv .box {
  position: absolute;
  bottom: 40px;
  left: calc((100% - 1200px) / 2);
}

.top-story {
  background: url(../images/title_bg.png) no-repeat top center/auto auto;
  padding-top: 60px;
  margin-bottom: 80px;
}

@media (min-width: 1601px) {
  .top-story {
    background-size: 100% auto;
  }
}

@media (max-width: 767px) {
  .top-story {
    background-size: auto 140px;
    padding-top: 30px;
  }
}

.top-story .common-title01 {
  color: #fff;
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .top-story .common-title01 {
    margin-bottom: 40px;
  }
}

.top-story p {
  padding: 50px 0;
  line-height: 2.4;
}

.top-category {
  background: #f2f2f2 url(../images/top_category_bg.png) no-repeat bottom left;
  padding: 70px 0;
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .top-category {
    background-size: 30% auto;
    padding: 20px 4%;
  }
}

.top-category .category-box {
  max-width: 1326px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 60px 30px 60px;
  background: #fff;
  position: relative;
}

@media (max-width: 767px) {
  .top-category .category-box {
    padding: 20px 4%;
  }
}

.top-category .top-cat-ico {
  position: absolute;
  top: -100px;
  right: 50px;
}

@media (max-width: 767px) {
  .top-category .top-cat-ico {
    width: 30%;
    right: 0%;
    top: -30px;
  }
}

.top-category .common-title01 {
  position: relative;
  z-index: 2;
}

.top-category ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
}

.top-category ul.col2, .top-category ul.col3-list {
  justify-content: space-between;
}

.top-category ul.col3-list li {
  width: 30%;
}

.top-category ul.half-width li {
  width: 48%;
}

.top-category ul.left-wide li:last-of-type {
  width: 30%;
}

.top-category ul.left-wide li:first-of-type {
  width: 66%;
}

.top-category ul.right-wide li:last-of-type {
  width: 66%;
}

.top-category ul.right-wide li:first-of-type {
  width: 30%;
}

.top-category ul.col1-list {
  flex-direction: column;
}

.top-category ul li {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .top-category ul li {
    margin-bottom: 14px;
  }
}

.top-category ul li a {
  display: block;
}

@media (min-width: 768px) {
  .top-category ul li a {
    transition: 0.2s;
  }
  .top-category ul li a img {
    transition: 0.2s;
  }
  .top-category ul li a:hover {
    color: #111;
  }
  .top-category ul li a:hover img {
    opacity: 0.8;
  }
}

.top-category ul h3 {
  font-size: 1.8rem;
  font-weight: bold;
  padding: 10px 0 10px 0;
}

@media (max-width: 767px) {
  .top-category ul h3 {
    font-size: 1.2rem;
    padding: 4px 0 0 0;
    line-height: 1.3;
  }
}

.top-category ul p {
  line-height: 1.8;
  text-align: justify;
  letter-spacing: 0.05em;
}

@media (max-width: 767px) {
  .top-category ul p {
    display: none;
  }
}

.top-howto {
  margin-bottom: 80px;
}

.howto-box {
  background: #f8f9fb url(../images/top_howto_bg.jpg) no-repeat top right/auto 100%;
  padding: 40px 40px 20px 40px;
}

@media (max-width: 767px) {
  .howto-box {
    padding: 15px 4% 15px 4%;
    position: relative;
    background: #f8f9fb url(../images/top_howto_bg.jpg) no-repeat 60% 50%/auto 100%;
    margin-bottom: 120px;
  }
}

.howto-box h2 {
  display: inline-block;
  color: #ae1b22;
  border: 1px solid #ae1b22;
  font-size: 3.2rem;
  line-height: 1;
  padding: 15px 40px;
  border-radius: 100px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .howto-box h2 {
    font-size: 2rem;
    padding: 10px 30px;
  }
}

.howto-box p {
  margin-bottom: 20px;
  line-height: 2;
}

@media (max-width: 767px) {
  .howto-box p.howto-txt {
    position: absolute;
    bottom: -90px;
    left: 0;
    margin-bottom: 0;
  }
}

.top-ranking {
  background: #00472b;
  margin-bottom: 80px;
  padding: 80px 0;
}

.top-ranking .common-title01 {
  color: #fff;
}

.ranking-list {
  padding-bottom: 20px;
}

.ranking-list li {
  margin-bottom: 20px;
}

.ranking-list a {
  display: block;
  color: #fff;
  padding: 20px;
}

@media (min-width: 768px) {
  .ranking-list a {
    transition: 0.2s;
  }
  .ranking-list a:hover {
    background: #005634;
  }
}

@media (max-width: 767px) {
  .ranking-list a {
    padding: 0 6%;
  }
}

.ranking-list a .thumb {
  margin-bottom: 10px;
}

.ranking-list a .thumb img {
  width: 100%;
}

.ranking-list a h3 {
  margin-bottom: 15px;
}

.top-news {
  margin-bottom: 80px;
}

.news-list li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}

@media (min-width: 768px) {
  .news-list li a {
    transition: 0.4s;
  }
  .news-list li a:hover {
    opacity: 0.6;
  }
}

.news-list li a .date {
  width: 120px;
}

.news-list li a .title {
  width: calc(120% - 100px);
}

.foot-content {
  margin-bottom: 80px;
}

.foot-content ul li {
  border: 1px solid #ccc;
  width: 23%;
}

@media (max-width: 767px) {
  .foot-content ul li {
    width: 48%;
    margin-bottom: 15px;
  }
}

.foot-content ul li a {
  padding: 20px;
  display: block;
}

@media (min-width: 768px) {
  .foot-content ul li a {
    transition: 0.4s;
  }
  .foot-content ul li a:hover {
    opacity: 0.6;
  }
}

@media (max-width: 767px) {
  .foot-content ul li a {
    padding: 10px;
  }
}

.foot-content ul li a h3 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.2rem;
}

@media (max-width: 767px) {
  .foot-content ul li a h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
}

.foot-content ul li a .ico {
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .foot-content ul li a .ico {
    margin-bottom: 10px;
    height: 45px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .foot-content ul li a .ico img {
    max-height: 100%;
  }
}

/* ===================================================================

subpages style

=================================================================== */
.sub-kv {
  height: 300px;
  margin-top: 106px;
  margin-bottom: 80px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: url(../images/sub/common_sub_kv.jpg) no-repeat center center/cover;
}

@media (max-width: 767px) {
  .sub-kv {
    margin-top: 0;
    margin-bottom: 40px;
    height: 140px;
  }
}

.sub-kv .common-title01 {
  margin-bottom: 0;
}

.about-cont {
  margin-top: -80px;
}

@media (max-width: 767px) {
  .about-cont {
    margin-top: -40px;
  }
}

.about-cont .box {
  max-width: 1000px;
}

.about-cont section {
  margin-bottom: 40px;
}

.about-cont section p {
  line-height: 2.2;
  letter-spacing: 0.1em;
}

.thumb.fl {
  margin-right: 20px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .thumb.fl {
    max-width: 40%;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.thumb.fr {
  margin-left: 20px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .thumb.fr {
    max-width: 40%;
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

.about-cont01 {
  background: url(../images/sub/about_bg01.jpg) no-repeat top center/cover;
  color: #fff;
  padding: 60px 0;
  position: relative;
}

@media (max-width: 767px) {
  .about-cont01 {
    padding: 30px 0;
  }
}

.about-cont01 p {
  line-height: 2.4;
}

.about-cont01 .txt-box {
  padding-right: 10px;
  width: 70%;
}

@media (max-width: 767px) {
  .about-cont01 .txt-box {
    padding-right: 0;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .about-cont01 .thumb {
    text-align: center;
    padding-top: 20px;
  }
  .about-cont01 .thumb img {
    max-width: 50%;
  }
}

.about-cont02 {
  background: url(../images/sub/about_bg02.jpg) no-repeat top right;
  padding-top: 100px;
  margin-top: -40px;
}

@media (max-width: 767px) {
  .about-cont02 {
    background-size: 60% auto;
  }
}

.pink-bg {
  background: #fff7f7;
  padding: 80px 0;
}

.about-cont03 h3:not(:first-of-type) {
  margin-top: 40px;
}

.common-bnr {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  padding: 80px 3% 0 3%;
}

@media (max-width: 767px) {
  .common-bnr {
    width: 100%;
    overflow: hidden;
    padding: 60px 4% 0 4%;
  }
}

.common-bnr a {
  display: block;
  position: relative;
  max-width: 912px;
}

@media (min-width: 768px) {
  .common-bnr a {
    transition: 0.4s;
  }
  .common-bnr a:hover {
    opacity: 0.6;
  }
}

@media (max-width: 767px) {
  .common-bnr a > img {
    height: 180px;
    max-width: none;
    width: auto;
    transform: translateX(-10%);
  }
}

.common-bnr a p {
  color: #00472b;
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  position: absolute;
  top: 40px;
  width: 100%;
  text-align: center;
}

@media (max-width: 767px) {
  .common-bnr a p {
    font-size: 1.8rem;
  }
}

.common-bnr a .btn-purchase {
  display: inline-block;
  background: #ae1b22;
  color: #fff;
  font-size: 1.6rem;
  padding: 15px 0px;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  width: 260px;
}

@media (max-width: 767px) {
  .common-bnr a .btn-purchase {
    padding: 10px 0;
    width: 220px;
  }
}

.common-bnr a .btn-purchase:after {
  content: "";
  width: 30px;
  height: 4px;
  position: absolute;
  right: 10px;
  top: calc(50% - 2px);
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: skew(40deg);
}

.report-cont {
  background: url(../images/sub/report_bg.jpg) repeat top left;
  padding: 40px;
}

@media (max-width: 767px) {
  .report-cont {
    padding: 20px 4%;
  }
}

.report-cont p {
  line-height: 2.2;
  margin-bottom: 20px;
}

.report-cont section {
  margin-bottom: 40px;
  position: relative;
}

.report-cont .btn-more {
  color: #535353;
  border-color: #535353;
}

.report-title01 {
  margin-bottom: 40px;
  text-align: center;
}

@media (max-width: 767px) {
  .report-title01 {
    margin-bottom: 20px;
  }
}

.report-title02 {
  margin-bottom: 20px;
  border-left: 5px solid #00472b;
  font-weight: bold;
  font-size: 2rem;
  padding-left: 10px;
  line-height: 1.3;
}

@media (max-width: 767px) {
  .report-title02 {
    font-size: 1.8rem;
  }
}

.report-cont01 .thumb {
  float: right;
  margin-top: -80px;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .report-cont01 .thumb {
    float: none;
    margin: 0px auto 20px auto;
    width: 70%;
  }
}

@media (max-width: 767px) {
  .report-cont02 .thumb {
    width: 70%;
    margin: 0 auto 20px auto;
    float: none;
  }
}

.report-cont06 .report-title01 {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .report-cont06 .thumb {
    width: 90%;
    margin: -40px auto 0px auto;
  }
}

.more-cont {
  display: none;
}

.howto-cont section,
.uv-cont section,
.doctor-cont section {
  margin-bottom: 80px;
  padding: 0 20px;
}

@media (max-width: 767px) {
  .howto-cont section,
  .uv-cont section,
  .doctor-cont section {
    margin-bottom: 40px;
  }
}

.howto-cont .intro,
.uv-cont .intro,
.doctor-cont .intro {
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .howto-cont .intro,
  .uv-cont .intro,
  .doctor-cont .intro {
    margin-bottom: 30px;
  }
}

.howto-cont .intro p,
.uv-cont .intro p,
.doctor-cont .intro p {
  line-height: 2;
}

.howto-cont .lead-txt,
.uv-cont .lead-txt,
.doctor-cont .lead-txt {
  margin-bottom: 40px;
  line-height: 2;
}

@media (max-width: 767px) {
  .howto-cont .lead-txt,
  .uv-cont .lead-txt,
  .doctor-cont .lead-txt {
    margin-bottom: 20px;
  }
}

.howto-title01 {
  text-align: center;
  overflow: hidden;
  color: #00472b;
  font-weight: bold;
  font-size: 2.2rem;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .howto-title01 {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
}

.howto-title01 span {
  position: relative;
}

.howto-title01 span:before {
  content: "";
  background: url(../images/sub/step_title_bg.png) repeat-x center center;
  width: 600px;
  height: 12px;
  position: absolute;
  left: -620px;
  top: calc(50% - 6px);
}

@media (max-width: 767px) {
  .howto-title01 span:before {
    background: none;
  }
}

.howto-title01 span:after {
  content: "";
  background: url(../images/sub/step_title_bg.png) repeat-x center center;
  width: 600px;
  height: 12px;
  position: absolute;
  right: -620px;
  top: calc(50% - 6px);
}

@media (max-width: 767px) {
  .howto-title01 span:after {
    background: none;
  }
}

.step-list {
  width: 105.3%;
  transform: translateX(-2.65%);
  counter-reset: stepNum;
}

@media (max-width: 767px) {
  .step-list {
    width: 100%;
    transform: translateX(0%);
  }
}

.step-list.step-green li:before {
  background: #00472b;
}

.step-list.step-green li:after {
  background: url(../images/sub/step_arw_g.png) no-repeat top left;
}

@media (max-width: 767px) {
  .step-list.step-green li:after {
    background-size: 100% auto;
  }
}

.step-list li {
  padding: 0 2.65%;
  position: relative;
  margin-bottom: 40px;
  counter-increment: stepNum;
}

@media (max-width: 767px) {
  .step-list li {
    padding: 0 0 45px 0;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
  }
}

.step-list li:before {
  content: "STEP" counter(stepNum);
  position: absolute;
  left: 0;
  right: 0;
  top: -15px;
  margin: auto;
  width: 80px;
  text-align: center;
  display: inline-block;
  background: #b1282e;
  color: #fff;
  font-size: 2rem;
  padding: 5px 15px;
  line-height: 1;
  border-radius: 40px;
  letter-spacing: 0.1em;
}

@media (max-width: 767px) {
  .step-list li:before {
    font-size: 1.4rem;
    top: 0px;
    right: auto;
    left: calc(40% + 4%);
  }
}

.step-list li:after {
  content: "";
  width: 36px;
  height: 110px;
  background: url(../images/sub/step_arw_r.png) no-repeat top left;
  position: absolute;
  top: 75px;
  right: -18px;
}

@media (max-width: 767px) {
  .step-list li:after {
    top: auto;
    right: 0;
    left: 0;
    bottom: -25px;
    margin: auto;
    transform: rotate(90deg);
    background-size: 100% auto;
    width: 18px;
    height: 55px;
  }
}

@media (min-width: 768px) {
  .step-list li:nth-of-type(3n):after, .step-list li:last-of-type:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .step-list li:last-of-type {
    padding-bottom: 0;
  }
  .step-list li:last-of-type:after {
    display: none;
  }
}

.step-list li .thumb {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .step-list li .thumb {
    width: 40%;
  }
}

.step-list li p {
  line-height: 1.5;
}

@media (max-width: 767px) {
  .step-list li p {
    width: 60%;
    padding-left: 4%;
    padding-top: 35px;
  }
}

.step-list li p .sml {
  font-size: 1.3rem;
}

.uv-list li {
  padding: 0 10px;
  text-align: center;
  margin-bottom: 30px;
}

.uv-list li a {
  display: block;
}

@media (min-width: 768px) {
  .uv-list li a {
    transition: 0.4s;
  }
  .uv-list li a:hover {
    opacity: 0.6;
  }
}

.uv-list li .thumb {
  margin-bottom: 15px;
  width: 100%;
  height: 300px;
}

.uv-list li p {
  display: inline-block;
  background: #00472b;
  color: #fff;
  line-height: 1;
  padding: 5px 15px;
  border-radius: 40px;
}

.doctor-cont {
  padding: 0;
}

.doctor-cont section {
  padding: 0;
}

.doctor-cont p {
  margin-bottom: 20px;
}

.common-tbl {
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  margin-bottom: 40px;
}

.common-tbl th,
.common-tbl td {
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

@media (max-width: 767px) {
  .common-tbl th,
  .common-tbl td {
    padding: 5px;
    font-size: 1rem;
  }
}

.faq-cont dl dt {
  border: 1px solid #00472b;
  line-height: 1.3;
  padding: 10px 40px 10px 10px;
  font-weight: bold;
  font-size: 1.8rem;
  margin-top: 10px;
  color: #00472b;
  position: relative;
  cursor: pointer;
}

@media (max-width: 767px) {
  .faq-cont dl dt {
    font-size: 1.4rem;
  }
}

.faq-cont dl dt:before {
  content: "Q.";
}

.faq-cont dl dt:after {
  content: "＋";
  line-height: 1;
  font-size: 2.4rem;
  position: absolute;
  right: 20px;
  top: calc(50% - 10px);
  transition: 0.4s;
}

@media (max-width: 767px) {
  .faq-cont dl dt:after {
    right: 10px;
    font-size: 1.8rem;
  }
}

.faq-cont dl dt.active:after {
  transform: rotate(405deg);
}

.faq-cont dl dd {
  display: none;
  padding: 20px 0;
}

.faq-cont01 {
  margin-bottom: 40px;
}

.faq-cont01 .sml {
  font-size: 1.2rem;
}

.faq-nav ul {
  margin-bottom: 40px;
}

.faq-nav ul li {
  text-align: center;
  padding: 10px;
}

@media (max-width: 767px) {
  .faq-nav ul li {
    padding: 10px 2px;
  }
}

.faq-nav ul li a {
  display: block;
  border: 1px solid #444;
  padding: 10px;
}

@media (min-width: 768px) {
  .faq-nav ul li a {
    transition: 0.4s;
  }
  .faq-nav ul li a:hover {
    opacity: 0.6;
  }
}

@media (max-width: 767px) {
  .faq-nav ul li a {
    padding: 10px 5px;
  }
}

.faq-tbl01 {
  padding: 20px 0;
}

.faq-tbl01 .tbl-l,
.faq-tbl01 .tbl-r {
  width: 48%;
}

.faq-tbl01 table {
  width: 100%;
  margin-bottom: 20px;
}

.faq-tbl01 th {
  background: #00472b;
  color: #fff;
}

.components {
  border: 2px solid #00472b;
  padding: 10px;
}

.components .trigger {
  color: #00472b;
  font-weight: bold;
  position: relative;
}

.components .trigger:after {
  content: "";
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #444;
  border-right: 1px solid #444;
  position: absolute;
  right: 10px;
  top: calc(50% - 5px);
  transform: rotate(45deg);
}

.components .trigger.active:after {
  transform: rotate(225deg);
}

.components .compo-inner {
  padding-top: 20px;
  display: none;
  font-size: 1.3rem;
}

.common-cont section {
  margin-bottom: 40px;
}

.common-cont section p {
  margin-bottom: 20px;
}

.guide-list li {
  padding: 0 20px;
}

@media (max-width: 767px) {
  .guide-list li {
    padding: 0 0 0px 0;
  }
}

.tc {
  text-align: center;
}

.pd-block img {
  max-width: 100%;
}

.pd-block * {
  box-sizing: border-box;
}

.pd-block ul,
.pd-block li,
.pd-block dl,
.pd-block dd,
.pd-block dt {
  list-style: none;
  margin: 0;
  padding: 0;
}

.pd-block a {
  color: #111;
}

.pd-block h3 {
  font-size: 1.8rem;
  line-height: 1.8;
  color: #0d472a;
}

.pd-block .teiki_notice h3 {
  background: #0d472a;
  color: #fff;
  margin-bottom: 15px;
  padding: 6px 10px;
}

.pd-block .teiki_notice .teiki_tbl {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap;
}

.pd-block .teiki_notice .teiki_tbl .dt {
  color: #0d472a;
  font-weight: bold;
  width: 150px;
}

@media (max-width: 767px) {
  .pd-block .teiki_notice .teiki_tbl .dt {
    width: 100%;
    line-height: 1;
    margin-bottom: 5px;
  }
}

.pd-block .teiki_notice .teiki_tbl .dt:before {
  content: "●";
}

.pd-block .teiki_notice .teiki_tbl .dd {
  width: calc(100% - 150px);
}

@media (max-width: 767px) {
  .pd-block .teiki_notice .teiki_tbl .dd {
    width: 100%;
    margin-bottom: 10px;
  }
}

.pd-block .bold {
  font-weight: bold;
}

.pd-block .pd-green-list {
  margin-bottom: 30px;
}

.pd-block .pd-green-list li {
  background: #0d472a;
  color: #fff;
  width: 32%;
  padding: 20px 20px 0 20px;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .pd-block .pd-green-list li {
    width: 100%;
    padding: 10px 10px 0 10px;
  }
}

.pd-block .pd-green-list li > div {
  width: 49%;
  font-size: 1.5rem;
  line-height: 1.8;
}

.pd-block .pd-green-list li > div:first-of-type {
  text-align: center;
}

.pd-block .pd-green-list li > div:first-of-type p.bold {
  text-align: left;
  font-size: 24px;
  line-height: 1;
}

.pd-block .pd-green-list li:nth-of-type(1) > div:first-of-type p.bold {
  padding-left: 22px;
}

.pd-block .pd-green-list li:nth-of-type(2) > div:first-of-type p.bold {
  padding-left: 33px;
}

.pd-block .pd-green-list li:nth-of-type(3) > div:first-of-type p.bold {
  padding-left: 22px;
}

@media (max-width: 767px) {
  .pd-block.top-category ul.half-width {
    flex-direction: column;
  }
  .pd-block.top-category ul.half-width li {
    width: 100%;
  }
  .pd-block.top-category ul.half-width p {
    display: block;
  }
}

.pd-kv {
  margin-top: 106px;
  margin-bottom: 40px;
  padding-top: 40px;
}

@media (max-width: 767px) {
  .pd-kv {
    margin-top: 0;
    margin-bottom: 40px;
  }
}

.pd-main {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .pd-main {
    flex-direction: column;
  }
}

.pd-main .slider-cont .slider-thumb {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap;
}

.pd-main .slider-cont .slider-thumb .slide {
  width: 18%;
  margin: 0 1% 10px 1%;
  cursor: pointer;
}

.pd-main .pd-main-l {
  width: 40%;
}

@media (max-width: 767px) {
  .pd-main .pd-main-l {
    width: 100%;
  }
}

.pd-main .pd-main-r {
  width: 56%;
}

@media (max-width: 767px) {
  .pd-main .pd-main-r {
    width: 100%;
  }
}

.pd-main .pd-main-r h2 {
  color: #fff;
  background: #00472b;
  font-size: 2rem;
  padding: 10px;
  margin-bottom: 20px;
  line-height: 1;
}

.pd-main .pd-main-r h3 {
  font-size: 2rem;
  color: #00472b;
  border-left: 5px solid #00472b;
  line-height: 1.5;
  padding: 5px 0 5px 10px;
  font-weight: bold;
  margin-bottom: 15px;
}

.pd-main .pd-main-r p {
  margin-bottom: 10px;
}

.pd-main .pd-main-r p.strike {
  font-size: 1.3rem;
  margin-bottom: 0;
  line-height: 1;
}

.pd-main .pd-main-r p.strike small {
  position: relative;
}

.pd-main .pd-main-r p.strike small:before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  background: #333;
}

.pd-main .pd-main-r p.price {
  font-size: 2rem;
  color: #ae1b22;
}

.pd-main .pd-main-r p.price span {
  font-size: 2.4rem;
}

.pd-main .pd-main-r p.sml {
  font-size: 1.4rem;
}

.pd-main .pd-main-r b {
  color: #00472b;
  font-weight: bold;
}

.pd-main .pd-main-r .teiki-exp {
  border: 1px solid #00472b;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 1.4rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .pd-main .pd-main-r .teiki-exp {
    flex-direction: column;
  }
}

.pd-main .pd-main-r .teiki-exp dt {
  width: 150px;
  font-weight: bold;
  color: #00472b;
}

@media (max-width: 767px) {
  .pd-main .pd-main-r .teiki-exp dt {
    width: 100%;
  }
}

.pd-main .pd-main-r .teiki-exp dd {
  width: calc(100% - 150px);
}

@media (max-width: 767px) {
  .pd-main .pd-main-r .teiki-exp dd {
    width: 100%;
    margin-bottom: 10px;
  }
}

.pd-main .pd-main-r .pd-tbl table {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  width: 100%;
}

.pd-main .pd-main-r .pd-tbl table th,
.pd-main .pd-main-r .pd-tbl table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  text-align: left;
}

.pd-main .pd-main-r .pd-tbl table th {
  background: #f1f1f1;
  font-weight: bold;
  text-align: center;
}

.pd-main .pd-main-r .pd-tbl table th:nth-of-type(2) {
  border-left: 1px solid #ccc;
}

.pd-main .pd-main-r .cart-area {
  padding: 20px 0;
  margin-bottom: 20px;
}

.pd-main .pd-main-r .cart-area > div {
  margin-bottom: 10px;
}

.pd-main .pd-main-r .cart-area select {
  margin-left: 10px;
  font-size: 1.4rem;
  padding: 5px;
  box-sizing: border-box;
}

.pd-main .pd-main-r .cart-area .btn {
  margin-top: 10px;
  appearance: none;
  background: #ae1b22;
  color: #fff;
  line-height: 1;
  padding: 15px 30px;
  font-size: 2rem;
  outline: none;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.pd-main .pd-main-r .cart-area .select-num-box {
  margin-bottom: 20px;
}

.pd-main .pd-main-r .cart-area .radio-list {
  margin-bottom: 20px;
}

.pd-main .pd-main-r .cart-area .radio-list li {
  border-bottom: 1px solid #eee;
  padding: 5px 0;
  font-size: 1.4rem;
}

.pd-main .pd-main-r .cart-area .radio-list li label {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  align-items: center;
}

.pd-main .pd-main-r .cart-area .radio-list li label input {
  margin-right: 10px;
}

.pd-main .pd-main-r .cart-area .select-list {
  margin-bottom: 20px;
}

.pd-main .pd-main-r .trigger {
  font-size: 2rem;
  font-weight: bold;
  border: 2px solid #00472b;
  padding: 5px 10px;
  position: relative;
  color: #00472b;
  margin-bottom: 20px;
}

.pd-main .pd-main-r .trigger.active:after {
  content: "ー";
  right: 14px;
}

.pd-main .pd-main-r .trigger:after {
  content: "＋";
  position: absolute;
  right: 15px;
  top: calc(50% - 6px);
  color: #fff;
  line-height: 1;
  font-size: 1.3rem;
}

.pd-main .pd-main-r .trigger:before {
  content: "";
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  background: #00472b;
  border-radius: 100%;
}

.pd-main .pd-main-r .close {
  display: none;
}

.pd-under {
  border-top: 1px solid #00472b;
  padding-top: 40px;
}

.pd-block h3 {
  font-weight: bold;
}

.option-images {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.option-images li {
  width: 23%;
  padding: 1%;
  margin: 0 1% 5px 1%;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-radius: 10px;
  transition: 0.3s;
}

.option-images li.active, .option-images li:hover {
  border: 2px solid #00472b;
  cursor: pointer;
}

.option-images li small {
  font-size: 1.3rem;
  line-height: 1.3;
  display: block;
}

.pd-dl {
  margin: 0 10px;
  border: 1px solid #ddd;
}

.pd-dl dt {
  padding: 10px;
  background: #f1f1f1;
  font-weight: bold;
}

.pd-dl dd {
  border-top: 1px solid #ddd;
  padding: 10px;
}

.products-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap;
}

.products-list li {
  width: 23%;
  margin: 0 1% 40px 1%;
  position: relative;
  display: none;
}

@media (max-width: 767px) {
  .products-list li {
    width: 46%;
    margin: 0 2% 20px 2%;
  }
}

.products-list li a {
  display: block;
  padding: 0 0 40px 0;
  height: 100%;
}

@media (max-width: 767px) {
  .products-list li a {
    padding: 0 0 60px 0;
  }
}

@media (min-width: 768px) {
  .products-list li a {
    transition: 0.4s;
  }
  .products-list li a:hover {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  }
}

.products-list li h3 {
  padding: 10px;
  line-height: 1.5;
  font-weight: bold;
}

@media (max-width: 767px) {
  .products-list li h3 {
    padding: 10px 0 0 0;
  }
}

.products-list li .price-box {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  padding: 0 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
}

@media (max-width: 767px) {
  .products-list li .price-box {
    flex-direction: column;
    padding: 0;
  }
}

.products-list li .price-box p {
  margin-right: 10px;
  margin-bottom: 0;
}

.products-intro {
  display: none;
  font-size: 1.8rem;
}

@media (max-width: 767px) {
  .products-intro {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .products-intro .btn-more:hover span {
    color: #9b9b9b;
  }
}

.products-intro .btn-more .close {
  display: none;
}

.products-intro .btn-more .open {
  display: inline-block;
}

.products-intro .btn-more.active .close {
  display: inline-block;
}

.products-intro .btn-more.active .open {
  display: none;
}

.products-intro .products-intro-box {
  display: none;
}

.products-intro .products-intro-box .intro-block {
  padding: 20px 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media (max-width: 767px) {
  .products-intro .products-intro-box .intro-block {
    flex-direction: column;
  }
}

.products-intro .products-intro-box .intro-block:nth-of-type(2n) {
  flex-direction: row;
}

@media (max-width: 767px) {
  .products-intro .products-intro-box .intro-block:nth-of-type(2n) {
    flex-direction: column;
  }
}

.products-intro .products-intro-box .intro-block .thumb {
  width: 36%;
}

@media (max-width: 767px) {
  .products-intro .products-intro-box .intro-block .thumb {
    width: 100%;
    margin-bottom: 20px;
  }
}

.products-intro .products-intro-box .intro-block .thumb small {
  font-size: 1.4rem;
  padding-top: 5px;
  font-style: italic;
  color: #666;
}

@media (max-width: 767px) {
  .products-intro .products-intro-box .intro-block .thumb small {
    font-size: 1.2rem;
  }
}

.products-intro .products-intro-box .intro-block p {
  width: 60%;
  text-align: justify;
  line-height: 2;
  font-size: 1.7rem;
}

@media (max-width: 767px) {
  .products-intro .products-intro-box .intro-block p {
    width: 100%;
    font-size: 1.4rem;
  }
}

.w20 {
  width: 20%;
}

.w40 {
  width: 40%;
}

.sml {
  font-size: 1.4rem;
}

.italic {
  font-style: italic;
}

/* ===================================================================

keyframes

=================================================================== */
@keyframes spinner1_1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spinner_loading_text {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes showUp {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showUp {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes hideDown {
  0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    visibility: hidden;
    display: none;
    opacity: 0;
  }
}

@keyframes hideDown {
  0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    visibility: hidden;
    display: none;
    opacity: 0;
  }
}

@-webkit-keyframes shakeIco {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-20deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  70% {
    transform: rotate(0deg);
  }
}

@keyframes shakeIco {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-20deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  70% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes moreIco {
  0% {
    transform: scale(1);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    animation-timing-function: ease-out;
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    animation-timing-function: ease-in;
    opacity: 1;
  }
}

@keyframes moreIco {
  0% {
    transform: scale(1);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    animation-timing-function: ease-out;
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    animation-timing-function: ease-in;
    opacity: 1;
  }
}

@-webkit-keyframes bounceIco2 {
  0% {
    transform: translateX(0px);
  }
  20% {
    transform: translateX(3px);
  }
  40% {
    transform: translateX(-3px);
  }
  60% {
    transform: translateX(1px);
  }
  80% {
    transform: translateX(-1px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes bounceIco2 {
  0% {
    transform: translateX(0px);
  }
  20% {
    transform: translateX(3px);
  }
  40% {
    transform: translateX(-3px);
  }
  60% {
    transform: translateX(1px);
  }
  80% {
    transform: translateX(-1px);
  }
  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes bounceIco {
  0% {
    transform: scaleY(1) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-out;
  }
  15% {
    transform: scaleY(0.8) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-in;
  }
  30% {
    transform: scaleY(1) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-in;
  }
  37.5% {
    transform: scaleY(1.1) translateY(-10px);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  45% {
    transform: scaleY(1) translateY(-10px);
    transform-origin: center top;
    animation-timing-function: ease-in;
  }
  60% {
    transform: scaleY(1) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-out;
  }
  70% {
    transform: scaleY(0.95) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-in;
  }
  80% {
    transform: scaleY(1) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-out;
  }
}

@keyframes bounceIco {
  0% {
    transform: scaleY(1) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-out;
  }
  15% {
    transform: scaleY(0.8) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-in;
  }
  30% {
    transform: scaleY(1) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-in;
  }
  37.5% {
    transform: scaleY(1.1) translateY(-10px);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  45% {
    transform: scaleY(1) translateY(-10px);
    transform-origin: center top;
    animation-timing-function: ease-in;
  }
  60% {
    transform: scaleY(1) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-out;
  }
  70% {
    transform: scaleY(0.95) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-in;
  }
  80% {
    transform: scaleY(1) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-out;
  }
}

@-webkit-keyframes liFadeIn {
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes liFadeIn {
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
