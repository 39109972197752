@charset "UTF-8";

/* ===================================================================

init style

=================================================================== */

html {
  font-size: 62.5%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI",
    "Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic Medium", "Yu Gothic",
    Verdana, Meiryo, sans-serif;
  font-weight: 500;
  font-size: 10px;
  font-size: 1rem;
  line-height: 1.7;
  font-feature-settings: "palt";
  letter-spacing: 0.8px;
  color: #666;
  background: #fff;
  // &:before {
  //   content: "";
  //   width: 100%;
  //   height: 100%;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   background: #fff;
  //   transition: 0.4s;
  //   opacity: 1;
  //   z-index: 99999;
  // }
  // &.fadeout {
  //   &:before {
  //     z-index: -1;
  //     opacity: 0;
  //   }
  // }
}

_:lang(x)::-ms-backdrop,
.selector {
  font-family: "Segoe UI", Meiryo, sans-serif;
}

b,
strong,
.bold {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI semibold",
    "Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic", "Segoe UI", Verdana,
    Meiryo, sans-serif;
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴ Pro W3",
      "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro W3", "メイリオ",
      Meiryo, Verdana, sans-serif;
    width: 100%;
    overflow-x: hidden;
  }
}

a {
  text-decoration: none;
  color: #666;
}

a:hover {
  text-decoration: none;
  color: #666;
}

img {
  vertical-align: bottom;
  max-width: 100%;
}

select,
input,
textarea,
button {
  vertical-align: middle;
}

#wrap {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  color: #666;
  overflow-x: hidden;
  font-size: 1.6rem;
  @include mqXS {
    font-size: 1.3rem;
  }
}

main {
  display: block;
  padding-top: 0;
  @include mqXS {
    padding-top: $hhsp;
  }
}

/* ===================================================================

common style

=================================================================== */

// 基本的なメインコンテンツ

.box {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  @include mqXS {
    max-width: 100%;
    padding: 0 5%;
    box-sizing: border-box;
  }
}

.box-narrow {
  width: 850px;
  margin-right: auto;
  margin-left: auto;
  @include mqXS {
    max-width: 100%;
    padding: 0 8%;
    box-sizing: border-box;
  }
}

.box-wide {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

.inner-box {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  @include mqXS {
    width: 88.7%;
  }
}

.full-width {
  width: 100%;
}

// テキスト周り

.fz-large {
  font-size: 2rem;
  @include mqXS {
    font-size: 1.6rem;
  }
}

.tj {
  text-align: justify;
}

.lh1 {
  line-height: 1;
}

.lh1-1 {
  line-height: 1.1;
}

.lh1-2 {
  line-height: 1.2;
}

.lh1-3 {
  line-height: 1.3;
}

.lh1-4 {
  line-height: 1.4;
}

.lh1-5 {
  line-height: 1.5;
}

.lh1-6 {
  line-height: 1.6;
}

.lh1-7 {
  line-height: 1.7;
}

.lh1-8 {
  line-height: 1.8;
}

.lh1-9 {
  line-height: 1.9;
}

.lh2 {
  line-height: 2;
}

.ls1 {
  letter-spacing: 0.1em;
}

.ls2 {
  letter-spacing: 0.2em;
}

.ls3 {
  letter-spacing: 0.3em;
}

.ls4 {
  letter-spacing: 0.4em;
}

.ls5 {
  letter-spacing: 0.5em;
}

// title周り

.common-title01 {
  font-size: 3.4rem;
  line-height: 1.3;
  font-weight: bold;
  color: $clr01;
  letter-spacing: 0.1em;
  margin-bottom: 40px;
  text-align: center;
  @include mqXS {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  span {
    font-weight: lighter;
    font-size: 1.6rem;
    display: block;
    letter-spacing: 0.3em;
    @include mqXS {
      font-size: 1.2rem;
    }
  }
}

.common-title02 {
  font-size: 2.4rem;
  margin-bottom: 40px;
  text-align: center;
  font-feature-settings: "palt";
  position: relative;
  padding-bottom: 15px;
  &:after {
    content: "";
    width: 50px;
    height: 1px;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  @include mqXS {
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
  &.tl {
    text-align: left;
  }
}

.common-title03 {
  font-size: 2.8rem;
  line-height: 1.5;
  margin-bottom: 30px;
  color: $clr08;
  font-weight: bold;
  letter-spacing: 0.08em;
  @include mqXS {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}

.common-title04 {
  background: $clr08;
  text-align: center;
  font-size: 2.8rem;
  color: #fff;
  font-weight: bold;
  padding: 15px 0;
  margin-bottom: 30px;
  line-height: 1.3;
  @include mqXS {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  &.green-bg {
    background: $clr01;
  }
}

.common-title05 {
  font-size: 2rem;
  color: $clr01;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.3;
  @include mqXS {
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
}

.tc {
  @include mqXS {
    &.sp-tl {
      text-align: left;
    }
  }
}

.mt-40 {
  margin-top: -40px;
}

// ボタン周り

a {
  &.btn-common {
    @include commonBtn;
    font-size: 1.8rem;
  }
  &.btn-white {
    @include commonBtn(#fff, #666);
  }
  &.btn-dark {
    @include commonBtn(#666, #fff);
  }
  &.btn-pink {
    @include commonBtn($clr01, #fff);
  }
  &.size-sml {
    padding: 14px 30px;
    font-size: 2rem;
    @include mqSM {
      &:before {
        right: 5px;
      }
      &:after {
        right: 13px;
      }
    }
    @include mqXS {
      padding: 14px 20px;
      font-size: 1.2rem;
    }
  }
  &.size-xsml {
    font-size: 1.6rem;
    padding: 15px 20px;
    @include mqXS {
      font-size: 1.4rem;
    }
  }
  &.size-medium {
    font-size: 3rem;
    padding: 15px 0;
    @include mqSM {
      &:before {
        width: 30px;
        height: 30px;
        top: calc(50% - 15px);
      }
      &:after {
        width: 12px;
        height: 12px;
        top: calc(50% - 6px);
        right: 23px;
      }
    }
    @include mqXS {
      font-size: 1.8rem;
    }
  }
  &.size-large {
    font-size: 3.6rem;
    padding: 15px 30px;
    @include mqSM {
      &:before {
        width: 36px;
        height: 36px;
        top: calc(50% - 18px);
      }
      &:after {
        width: 14px;
        height: 14px;
        top: calc(50% - 7px);
        right: 24px;
      }
    }
    @include mqXS {
      font-size: 1.8rem;
    }
  }
}
.btn-more {
  cursor: pointer;
  padding: 15px 0;
  width: 200px;
  font-size: 1.4rem;
  line-height: 1;
  color: #9b9b9b;
  border: 1px solid #9b9b9b;
  overflow: hidden;
  display: inline-block;
  @include mqXS {
    padding: 15px 0;
    font-size: 1.2rem;
    border-width: 1px;
  }
  @include mqSM {
    transition: 0.4s 0.1s;
    overflow: hidden;
    position: relative;
    z-index: 1;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: #696969;
      position: absolute;
      z-index: -1;
      left: -100%;
      top: 0;
      transition: 0.4s cubic-bezier(1, 0.05, 0.37, 1.01);
    }
    &:hover {
      color: #fff;
      &:before {
        left: 0;
        width: 105%;
      }
    }
  }
  &:before {
    z-index: 0;
  }
  span {
    transition: 0.4s;
    position: relative;
    z-index: 1;
  }
  &:hover {
    span {
      color: #fff;
    }
  }
  &.c-white {
    color: #fff;
    border: 1px solid #fff;
  }
  &.c-green {
    color: #fff;
    border: 1px solid $clr01;
    background: $clr01;
  }
  &.btn-wider {
    width: 400px;
    @include mqXS {
      width: 100%;
    }
  }
}

// flex周り

.flex {
  @include flex;
}

.fd-r {
  flex-direction: row-reverse;
}

.fd-cr {
  flex-direction: column-reverse;
}

.fd-c {
  flex-direction: column;
}

.jc-c {
  justify-content: center;
}

.jc-sb {
  justify-content: space-between;
}

.jc-fe {
  justify-content: flex-end;
}

.ai-c {
  align-items: center;
}

.ai-fe {
  align-items: flex-end;
}
.sp-flex-wrap {
  @include mqXS {
    flex-wrap: wrap;
  }
}
.sp-fd-c {
  @include mqXS {
    flex-direction: column;
  }
}
.sp-fd-cr {
  @include mqXS {
    flex-direction: column-reverse;
  }
}

// レイアウト

.col2-list {
  @include flex;
  flex-wrap: wrap;
  li {
    width: 50%;
  }
}

.col3-list {
  @include flex;
  flex-wrap: wrap;
  li {
    width: 33.33%;
  }
}

.col4-list {
  @include flex;
  flex-wrap: wrap;
  li {
    width: 25%;
  }
}

.col5-list {
  @include flex;
  flex-wrap: wrap;
  li {
    width: 20%;
  }
}

.sp-col2-list {
  @include mqXS {
    li {
      width: 50%;
    }
  }
}

.sp-col1-list {
  @include mqXS {
    li {
      width: 100%;
    }
  }
}

// inviewアニメーション

.anm {
  transition: 1s;
  &.fading {
    opacity: 0;
  }
  &.to-up {
    transform: translateY(20px);
  }
  &.to-down {
    transform: translateY(-20px);
  }
  &.to-right {
    transform: translateX(-10px);
  }
  &.to-left {
    transform: translateX(10px);
  }
  &.to-left-zoomin {
    transform: translateX(10px) scale(0.9);
    transform-origin: right bottom;
  }
  &.blur {
    filter: blur(10px);
  }
  &.ease-back {
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    &.to-up {
      transform: translateY(40px);
    }
  }
  &.is-show {
    &.fading {
      opacity: 1;
    }
    &.to-up {
      transform: translateY(0px);
    }
    &.to-down {
      transform: translateY(0px);
    }
    &.to-right {
      transform: translateX(0px);
    }
    &.to-left {
      transform: translateX(0px);
    }
    &.to-left-zoomin {
      transition: 2s 0.4s;
      transform: translateX(0px) scale(1);
    }
    &.blur {
      filter: blur(0px);
    }
    &.delay1 {
      transition-delay: 0.2s;
    }
    &.delay2 {
      transition-delay: 0.4s;
    }
    &.ease-back {
      transition-duration: 2s;
      transition-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
      &.to-up {
        transform: translateY(0px);
      }
    }
  }
}

.red {
  color: $clr03;
}

.lime {
  color: $clr06;
}

.pink {
  color: $clr05;
}

// ofi

img.ofi {
  object-fit: cover;
  object-position: center;
  font-family: "object-fit: cover;";
  width: 100%;
  height: 100%;
}

// PC・SPのオンオフ

.pc {
  display: block !important;
  @include mqXS {
    display: none !important;
  }
}
.sp {
  display: none !important;
  @include mqXS {
    display: block !important;
  }
}

/* Loading */

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  width: 40px;
  height: 40px;
  z-index: 99999;
}

.spinner span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
}

.spinner.type1 {
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: #333 rgba(30, 30, 30, 0.12) rgba(30, 30, 30, 0.12);
  animation: spinner1_1 1.5s infinite linear forwards;
}
.spinner.type1 span {
  animation: spinner_loading_text 1.5s infinite linear forwards reverse;
}

// slick slideで高さを100%に

.slider-full-height {
  height: 100%;
  .slick-list {
    height: 100%;
    .slick-track {
      height: 100%;
      .slick-slide {
        height: 100%;
        > div {
          height: 100%;
          .each-slide {
            height: 100%;
            > div {
              height: 100%;
            }
          }
        }
      }
    }
  }
}

/* ===================================================================

header style

=================================================================== */

#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  color: #444;
  background: #fff;
  transition: 0.2s;
  &.trans {
    background: transparent;
  }
  .head-inner {
    position: relative;
    @include flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 20px 30px;
    @include mqXS {
      padding: 15px 4% 15px 4%;
      z-index: 1;
    }
    .head-logo {
      margin: 0;
      @include mqXS {
        width: 24%;
      }
    }
  }
  &.h-white {
    background: transparent;
  }
}

.hnav {
  position: relative;
  z-index: 1001;
  @include mqXS {
    display: none;
  }
  ul {
    @include flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 50px;
    li {
      margin-left: 20px;
      font-size: 1.4rem;
      line-height: 1;
      @include mqXS {
        margin-left: 10px;
        font-size: 1.3rem;
      }
      &.h-tel {
        font-size: 2.4rem;
        font-weight: bold;
      }
      a {
        @include flex;
        align-items: center;
        img {
          margin-left: 5px;
        }
        @include mqSM {
          transition: 0.2s;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

.nav-btn {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  width: 25px;
  height: 18px;
  z-index: 10;
  margin: auto;
  cursor: pointer;
  z-index: 10001;
  @include mqXS {
    right: 4%;
    top: 0px;
  }
  &:before {
    content: "";
    width: 25px;
    height: 2px;
    background: $clr02;
    position: absolute;
    top: 0px;
    right: 0;
    transition: 0.4s;
  }
  &:after {
    content: "";
    width: 25px;
    height: 2px;
    background: $clr02;
    position: absolute;
    top: 16px;
    right: 0;
    transition: 0.4s;
  }
  span {
    display: block;
    width: 25px;
    height: 2px;
    background: $clr02;
    position: absolute;
    top: 8px;
    right: 0;
    transition: 0.4s;
  }
  &.active {
    &:before {
      content: "";
      width: 20px;
      height: 2px;
      top: 10px;
      right: 0;
      transform: rotate(45deg);
      background: $clr02;
    }
    &:after {
      content: "";
      width: 20px;
      height: 2px;
      top: 10px;
      right: 0;
      transform: rotate(-45deg);
      background: $clr02;
    }
    span {
      opacity: 0;
    }
    .close-txt {
      opacity: 1;
      transition: 0.4s 0.2s;
      padding-left: 40px;
    }
  }
}

.gnav {
  background: rgba(0, 71, 43, 0.9);
  @include mqXS {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    padding-top: 60px;
    transition: 0.2s;
    transform: translateY(-100%);
    overflow: scroll;
    &.on {
      transform: translateY(0%);
    }
  }
  > ul {
    @include flex;
    justify-content: space-between;
    color: #fff;
    position: relative;
    @include mqXS {
      color: #6c6c6c;
      flex-direction: column;
    }
    li {
      @include mqXS {
        width: 100%;
      }
      > a,
      .gnav-toggle {
        color: #fff;
        cursor: pointer;
        display: inline-block;
        padding: 10px 10px;
        line-height: 1;
        @include mqSM {
          transition: 0.2s;
          &:hover {
            background: $clr07;
            color: #aacec0;
          }
        }
        @include mqXS {
          color: #6c6c6c;
          display: block;
          width: 100%;
          border-bottom: 1px solid #ddd;
          font-size: 1.4em;
        }
      }
      .gnav-toggle {
        @include mqXS {
          position: relative;
          &:after {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-bottom: 1px solid $clr01;
            border-right: 1px solid $clr01;
            position: absolute;
            right: 0;
            bottom: calc(50% - 4px);
            transform: rotate(45deg);
            transition: 0.2s;
          }
        }
      }
    }
  }
  .gnav-inner-wrap {
    .gnav-inner {
      overflow: hidden;
      @include animationValue(hideDown, 0.2, ease-out, 0, 1, forwards);
      position: absolute;
      top: 35px;
      padding-top: 10px;
      @include mqXS {
        display: none;
        position: static;
        padding-top: 0;
      }
      ul {
        color: #6c6c6c;
        background: #fff;
        padding: 20px 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        @include mqXS {
          padding: 0;
          box-shadow: none;
        }
        li {
          a {
            color: #6c6c6c;
            font-size: 1.6rem;
            @include mqSM {
              transition: 0.2s;
              border-bottom: 1px solid transparent;
              &:hover {
                color: #fff;
              }
            }
            @include mqXS {
              font-size: 1.4rem;
              padding-left: 4%;
            }
          }
        }
      }
    }
    &.on {
      .gnav-inner {
        overflow: visible;
        visibility: visible;
        @include animationValue(showUp, 0.2, ease-out, 0, 1, forwards);
      }
      &.products {
        .gnav-inner {
          width: 100%;
        }
      }
      .gnav-toggle {
        &:after {
          @include mqXS {
            transform: rotate(225deg);
            bottom: calc(50% - 6px);
          }
        }
      }
    }
    &.products {
      .gnav-inner {
        left: 0;
        width: 100%;
        ul {
          @include flex;
          flex-wrap: wrap;
          li {
            width: 20%;
            @include mqXS {
              width: 50%;
            }
            a {
              @include flex;
              align-items: center;
              .pd-txt {
                padding-left: 10px;
                line-height: 1.3;
                @include mqXS {
                  font-size: 1.2rem;
                  display: block;
                  width: 75%;
                }
              }
              .pd-ico {
                @include mqXS {
                  width: 25%;
                }
                img {
                  max-height: 46px;
                  @include mqXS {
                    height: 40px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// .gnav {
//   position: fixed;
//   top: 0;
//   right: 0;
//   width: 300px;
//   height: 100%;
//   background: #fff;
//   z-index: 1002;
//   transition: 0.4s cubic-bezier(1, 0.05, 0.37, 1.01);
//   padding: 100px 20px 20px 20px;
//   transform: translateX(100%);
//   box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.05);
//   @include mqXS {
//     width: 60%;
//   }
//   a {
//     color: $clr02 !important;
//   }
//   &.active {
//     transform: translateX(0%);
//     box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.05);
//     ul {
//       opacity: 1;
//       transform: translateX(0px);
//       transition: 0.4s 0.4s;
//     }
//   }
//   ul {
//     opacity: 0;
//     transform: translateX(20px);
//     transition: 0.4s;
//     li {
//       a {
//         display: block;
//         border-bottom: 1px solid #ddd;
//         padding: 20px 0;
//         font-size: 1.8rem;
//         letter-spacing: 0.1em;
//         @include mqSM {
//           transition: 0.2s;
//           &:hover {
//             border-bottom: 1px solid #999;
//           }
//         }
//         @include mqXS {
//           font-size: 1.4rem;
//           padding: 10px 0;
//         }
//       }
//     }
//   }
// }

/* ===================================================================

footer style

=================================================================== */

#footer {
  padding: 40px 0 100px 0;
  background: #1d684a;
  color: #fff;
  position: relative;
  margin-top: 80px;
  text-align: left;
  @include mqXS {
    padding: 40px 0 40px 0;
  }
  .foot-head {
    @include flex;
    justify-content: space-between;
    align-items: center;
    @include mqXS {
      .flogo {
        width: 80%;
        margin: 0 auto 10px auto;
        text-align: center;
      }
    }
    .fnav01 {
      ul {
        @include flex;
        justify-content: flex-end;
        align-items: center;
        @include mqXS {
          justify-content: center;
          padding-top: 10px;
        }
        li {
          margin-left: 20px;
          @include mqXS {
            margin: 0 10px;
            &:last-of-type {
              display: none;
            }
          }
        }
      }
    }
  }
  .foot-nav {
    padding: 50px 0 45px 0;
    @include mqXS {
      padding: 20px 0;
    }
    .fnav02 {
      @include flex;
      justify-content: space-between;
      font-size: 1.6rem;
      @include mqXS {
        display: none;
      }
      p {
        font-weight: bold;
        margin-bottom: 15px;
        height: 20px;
      }
      ul {
        li {
          margin-bottom: 5px;
          a {
            color: #aacec0;
            @include commonHover;
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  .foot-sns {
    margin-bottom: 50px;
    @include mqXS {
      margin-bottom: 20px;
    }
    ul {
      @include flex;
      justify-content: center;
      align-items: center;
      li {
        margin: 0 10px;
        a {
          @include commonHover;
        }
      }
    }
  }
  .foot-address {
    font-size: 1.8rem;
    margin-bottom: 10px;
    @include mqXS {
      font-size: 1.3rem;
    }
  }
  .foot-copy {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    color: #fff;
    background: #00472b;
    padding: 10px 0;
    @include mqXS {
      font-size: 1.1rem;
    }
  }
}

.sp-fnav {
  text-align: center;
  ul {
    margin-bottom: 20px;
    border-top: 1px solid #aacec0;
    li {
      a {
        display: block;
        color: #aacec0;
        border-bottom: 1px solid #aacec0;
        padding: 10px 0;
      }
    }
  }
}
