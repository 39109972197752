@charset "UTF-8";

/* ===================================================================

keyframes

=================================================================== */

@keyframes spinner1_1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spinner_loading_text {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@include keyframes(showUp) {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@include keyframes(hideDown) {
  0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    visibility: hidden;
    display: none;
    opacity: 0;
  }
}

@include keyframes(shakeIco) {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-20deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  70% {
    transform: rotate(0deg);
  }
}

@include keyframes(moreIco) {
  0% {
    transform: scale(1);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    animation-timing-function: ease-out;
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    animation-timing-function: ease-in;
    opacity: 1;
  }
}

@include keyframes(bounceIco2) {
  0% {
    transform: translateX(0px);
  }
  20% {
    transform: translateX(3px);
  }
  40% {
    transform: translateX(-3px);
  }
  60% {
    transform: translateX(1px);
  }
  80% {
    transform: translateX(-1px);
  }
  100% {
    transform: translateX(0px);
  }
}

@include keyframes(bounceIco) {
  0% {
    transform: scaleY(1) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-out;
  }
  15% {
    transform: scaleY(0.8) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-in;
  }
  30% {
    transform: scaleY(1) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-in;
  }
  37.5% {
    transform: scaleY(1.1) translateY(-10px);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  45% {
    transform: scaleY(1) translateY(-10px);
    transform-origin: center top;
    animation-timing-function: ease-in;
  }
  60% {
    transform: scaleY(1) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-out;
  }
  70% {
    transform: scaleY(0.95) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-in;
  }
  80% {
    transform: scaleY(1) translateY(0px);
    transform-origin: center bottom;
    animation-timing-function: ease-out;
  }
}

@include keyframes(liFadeIn) {
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
