@charset "UTF-8";

/* ===================================================================

scss core settings

=================================================================== */

// colors

$clr01: #00472b;
$clr02: #444;
$clr03: #ae1b22;
$clr04: #f2f2f2;
$clr05: #c8161e;
$clr06: #15ae67;
$clr07: #1d684a;
$clr08: #b1282e;
$clr09: #fff7f7;

$hh: 106px;
$hhsp: 57px;

// media queries

$widthSM: 768px;

@mixin mqSM {
  //$widthXL（768px）以上の場合
  @media (min-width: $widthSM) {
    @content;
  }
}
@mixin mqXS {
  //$widthSM-1（767px）以下の場合
  @media (max-width: ($widthSM - 1)) {
    @content;
  }
}

@mixin mqLG {
  //1600以上の場合
  @media (min-width: (1601px)) {
    @content;
  }
}

// fonts

@mixin hiragino() {
  font-family: "ヒラギノ角ゴ Pro W3", "HiraKakuPro-W3", sans-serif;
}

@mixin mincho() {
  font-family: "游明朝", YuMincho, Yu Mincho, "ヒラギノ明朝 ProN W3",
    "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

@mixin marugo() {
  font-family: fot-tsukuardgothic-std, "ヒラギノ角ゴ Pro W3", "HiraKakuPro-W3",
    sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin gibbs() {
  font-family: gibbs, sans-serif;
  font-weight: 300;
  font-style: normal;
}

// other mixins

@mixin flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
}

@mixin clearfix() {
  min-height: 1px;
  &:after {
    content: " ";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
  }
}

@mixin animationValue($name, $duration, $function, $delay, $count, $state) {
  -webkit-animation: $name $duration + s $function $delay + s $count $state;
  animation: $name $duration + s $function $delay + s $count $state;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin commonBtn($btnBg: #fff, $btnClr: #666) {
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  line-height: 1;
  background: $btnBg;
  color: $btnClr;
  @include mqXS {
    font-weight: bold;
  }
  @include mqSM {
    transition: 0.4s;
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      opacity: 0;
      transform: translateX(5px);
      transition: 0.4s;
    }
    &:before {
      width: 20px;
      height: 20px;
      right: 10px;
      top: calc(50% - 10px);
      background: $btnClr;
      border-radius: 100%;
    }
    &:after {
      width: 6px;
      height: 6px;
      right: 18px;
      top: calc(50% - 3px);
      border-bottom: 1px solid $btnBg;
      border-right: 1px solid $btnBg;
      transform: translateX(5px) rotate(-45deg);
    }
    &:hover {
      &:before {
        opacity: 1;
        transform: translateX(0px);
      }
      &:after {
        opacity: 1;
        transform: translateX(0px) rotate(-45deg);
        transition: 0.4s 0.1s;
      }
    }
  }
}

@mixin grad01($gradClr01, $gradClr02) {
  content: "";
  width: 100%;
  height: 45px;
  position: absolute;
  background: $gradClr01;
  background: -moz-linear-gradient(top, $gradClr01 0%, $gradClr02 100%);
  background: -webkit-linear-gradient(top, $gradClr01 0%, $gradClr02 100%);
  background: linear-gradient(to bottom, $gradClr01 0%, $gradClr02 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradClr01', endColorstr='$gradClr02',GradientType=0 );
  @include mqXS {
    height: 30px;
  }
}

@mixin commonHover($hoverOpacity: 0.6) {
  @include mqSM {
    transition: 0.4s;
    &:hover {
      opacity: $hoverOpacity;
    }
  }
}
